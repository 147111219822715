import { CANCEL_SUBSCRIPTION } from "app/redux/types/subscription";

const zh = {
  translation: {
    // Sider bar
    TODAY: "今日",
    YESTERDAY: "昨天",
    SEVEN_DAYS: "7天內",
    USER_DATA: "個人資料",
    // Home
    NOTE_LIST: "筆記列表",
    NOTE_SELECTED_PRE: "已選取",
    NOTE_SELECTED: "篇筆記選取",
    // Setting
    USER_SETTING: "使用者設定",
    INTERFACE_LANG: "界面語言",
    CONTENT_LANG: "內容語言",
    MAGIC_POINT: "魔法值",
    REF_CODE: "推薦碼",
    LOGOUT: "登出",
    PLAN_CONTENT: "方案內容",
    PLAN: "方案",
    PLAN_FREE: "免費",
    PLAN_STANDARD: "基本",
    PLAN_PREMIUM: "高級",
    PLAN_UPGRADE: "升級方案",
    SUBSCRIPTION_RECORD: "訂閱紀錄",
    CANCEL_SUBSCRIPTION: "取消訂閱",
    SUBSCRIPTION_TIME: "訂閱時間",
    EXPIRATION_TIME: "到期時間",
    NO_RECORD: "無訂閱紀錄",
    REDEEM_CODE: "推薦碼兌換",
    REDEEM_CODE_BTN: "兌換",
    CHANNELS_SETTING: "輸入管道",
    CHANNEL_PROVIDER: "類型",
    ADD_EXPORT_CHANNEL: "新增輸出管道",
    EXPORTS: "輸出管道",
    EXPORT_TYPE: "類型",
    CREATED_AT: "創建時間",
    // NOTE
    ALL: "全部",
    LINK_NOTE: "連結筆記",
    IMAGE_NOTE: "圖片筆記",
    AUDIO_NOTE: "音訊筆記",
    TEXT_NOTE: "文字筆記",
    FILE_NOTE: "檔案筆記",
    SUMMARY_NOTE: "摘要筆記",
    POLISH_NOTE: "潤飾筆記",
    TRANSLATE_NOTE: "翻譯筆記",
    NOTE_CREATED_AT: "建立時間",
    NOTE_TAGS: "關鍵字",
    NOTE_TAGS_GENERATING: "AI 產生標籤中⋯",
    NOTE_LABELS: "標籤",
    NOTE_LABELS_ADD: "新增＋",
    NOTE_URL: "連結",
    NOTE_SHARE: "分享",
    NOTE_TITLE: "標題",
    NOTE_REMAINING_TIMES: "剩餘時間",
    NOTE_TASK_TYPE: "任務類型",
    NOTE_VIEW: "觀看筆記",
    DEL_TASK: "刪除任務",
    // CONFIRM MODAL
    MODAL_CONFIRM: "確認",
    MODAL_CANCEL: "取消",
    MODAL_TITLE_WARNING: "警告",
    MODAL_DELETE_NOTE: "確定要刪除此篇筆記嗎？",
    // FOOTER
    FOOTER_HOME: "首頁",
    FOOTER_SETTING: "個人設定",
    // ALERT
    REDEEM_CODE_SUCCESS: "推薦碼兌換成功",
    REDEEM_CODE_FAIL: "推薦碼兌換失敗",
    // COMMON
    TRANSLATE: "翻譯",
    POLISH: "潤飾",
    SUMMARY: "摘要",
    UPLOAD: "檔案上傳",
    REFRESH: "重新整理",
    MINUTES: "分鐘",
    // OTHER
    NO_NOTES_FOUND: "未搜尋到任何筆記",
    NO_NOTES_CREATED: "目前無任何筆記建立",
  },
};

export default zh;
