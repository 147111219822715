import React, { useCallback, useMemo, useRef } from "react";
import MediaPlayer from "./MediaPlayer";
import ContentEditor from "./Editor";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import MindMap from "./MindMap";
import { updNoteContent } from "app/redux/actions/note";
import { genContent } from "app/utils/downloadDoc";

const NoteEditor = ({
  sourceType,
  userId,
  noteId,
  isImmersive,
  noteView,
  handleSwitchImmersive,
}) => {
  const dispatch = useDispatch();
  const lastSavedContentRef = useRef("");
  const config = useSelector(({ app }) => app.user.config);
  const note = useSelector(({ search }) => search.getIn(["results", "note"]));

  const prevNoteViewRef = useRef(noteView);

  // 優化內容生成
  const content = useMemo(() => genContent(note, noteView), [note, noteView]);

  const handleContentDurationChange = useCallback(
    editor => {
      const currentView = noteView;

      if (prevNoteViewRef.current !== currentView) {
        console.log("Note View Change");
        prevNoteViewRef.current = currentView;
        return;
      }

      if (
        sourceType === "audio" &&
        (noteView === "translate" || noteView === "content")
      ) {
        return;
      }

      const editedContent = editor.getHTML();
      let finalContent;
      let view = noteView;

      // 處理生成的內容 (array of {type, content})
      if (noteView.includes("generated")) {
        console.log("Note View Change Generated");
        view = "generated";
        const index = parseInt(noteView.split("-")[1], 10);

        // 檢查當前編輯的內容是否有變化
        if (note.generated[index]?.content === editedContent) {
          return;
        }

        // 創建新的 generated array，更新指定索引的內容
        finalContent = note.generated.map((item, i) =>
          i === index ? { ...item, content: editedContent } : item
        );

        // 檢查整個陣列是否有實際變化
        const hasArrayChanged = finalContent.some(
          (item, i) => item.content !== note.generated[i].content
        );

        if (!hasArrayChanged) {
          return;
        }
      } else {
        console.log("Note View Change Normal");
        // 處理一般內容 (string)
        finalContent = editedContent;

        // 如果內容沒有變化，直接返回
        if (note[noteView] === editedContent) {
          return;
        }
      }

      // 更新最後保存的內容
      lastSavedContentRef.current = editedContent;

      console.log("Note Save");

      // 發送更新請求
      return dispatch(updNoteContent(userId, noteId, finalContent, view));
    },
    [noteView, note, sourceType, userId, noteId]
  );

  if (!note || !config) return <Loading />;

  if (sourceType === "audio") {
    if (noteView === "content" || noteView === "translate") {
      return (
        <MediaPlayer
          userId={userId}
          noteId={noteId}
          content={note[noteView]}
          url={note.fileurl}
          isImmersive={isImmersive}
          handleSwitchImmersive={handleSwitchImmersive}
        />
      );
    }
    if (noteView === "mindmap") {
      return <MindMap input={note["mindmap"]} />;
    }
  }

  return (
    <ContentEditor
      note={note}
      noteId={noteId}
      userId={userId}
      noteView={noteView}
      locales={config?.locales}
      content={content}
      onContentDurationChange={handleContentDurationChange}
    />
  );
};

export default React.memo(NoteEditor, (prevProps, nextProps) => {
  return (
    prevProps.noteView === nextProps.noteView &&
    prevProps.noteId === nextProps.noteId &&
    prevProps.userId === nextProps.userId &&
    prevProps.sourceType === nextProps.sourceType &&
    prevProps.isImmersive === nextProps.isImmersive
  );
});
