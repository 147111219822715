import React, { useEffect } from "react";
import NoteItemList from "./NoteItemList";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useDispatch, useSelector } from "react-redux";
import GridViewIcon from "@mui/icons-material/GridView";
import { selNotes, delNote, cngListView } from "app/redux/actions/note";
import ConfirmModal from "../ConfirmModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchNotes } from "app/redux/actions/search";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAlert from "app/hooks/useAlert";
import ExtraMenu from "./ExtraMenu";
import Loading from "../Loading";

const NoteList = ({ noteList, selectedNotes, isSearching }) => {
  const { showSuccessAlert } = useAlert();
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [noteId, setNoteId] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const uid = useSelector(({ app }) => app.user.id);
  const total_count = useSelector(({ search }) => search.get("total_count"));

  const config = useSelector(({ search }) => search.get("config"));

  const listView = useSelector(({ note }) =>
    note.getIn(["config", "listView"])
  );
  const labelList = useSelector(({ search }) =>
    search.getIn(["results", "labels"])
  );

  const handleChangeGridView = () => {
    dispatch(cngListView(!listView));
  };

  const handleSelect = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(selNotes(id, e.target.checked));
  };

  const handleDelete = () => {
    dispatch(delNote(uid, noteId));
    handleDelModalClose();
    return showSuccessAlert("已成功刪除文章");
  };

  const handleDelModalOpen = (nid, e) => {
    e.stopPropagation();
    setNoteId(nid);
    setDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
    setNoteId(null);
  };

  const handleFchMore = () => {
    dispatch(
      searchNotes({
        page: config.page + 1,
        keyword: config.keyword,
        start_date: config.start_date,
        end_date: config.end_date,
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const scrollContainer = document.getElementById("jumbo_card_content");
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
        scrollContainer.parentElement.scrollTop = 0;
      }
    }, 100);
  }, [config.searchCondition]);

  // TODO: DROPDOWN MENU

  return (
    <JumboCardQuick
      title={`${t("NOTE_LIST")}`}
      subheader={` ${selectedNotes.length} ${t("NOTE_SELECTED")}`}
      action={
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <GridViewIcon
            sx={{ mr: 1, cursor: "pointer" }}
            component="svg"
            onClick={handleChangeGridView}
          />
          <ExtraMenu />
        </Stack>
      }
      headerSx={{
        borderBottom: 1,
        borderBottomColor: "divider",
        px: 3,
      }}
      wrapperSx={{ p: 0, overflowY: "auto", height: "calc(100vh - 310px)" }}
      sx={{ height: "calc(100vh - 210px)" }}>
      {isSearching && <Loading />}
      {!isSearching && noteList.length === 0 && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 6 }}
          gap={1}>
          <img
            src="/images/logo-main.png"
            alt="logo"
            style={{ width: 80, height: 80 }}
          />
          {config.searchCondition.length === 0 && config.keyword === "" && (
            <Typography variant="h3">{t("NO_NOTES_CREATED")}</Typography>
          )}
          {(config.searchCondition.length > 0 || config.keyword !== "") && (
            <Typography variant="h3">{t("NO_NOTES_FOUND")}</Typography>
          )}
        </Stack>
      )}

      <InfiniteScroll
        dataLength={noteList.length}
        hasMore={noteList.length < total_count}
        next={handleFchMore}
        style={{ padding: "16px 16px 32px", height: "100%" }}
        scrollableTarget="jumbo_card_content"
        loader={
          <Box
            component="div"
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        }>
        <NoteItemList
          dataSource={noteList}
          listView={listView}
          labelList={labelList}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          selectedNotes={selectedNotes}
          handleDelModalOpen={handleDelModalOpen}
          searchCondition={config.searchCondition}
        />
      </InfiniteScroll>

      <ConfirmModal
        isOpen={delModalOpen}
        title={t("MODAL_TITLE_WARNING")}
        content={t("MODAL_DELETE_NOTE")}
        handleClose={handleDelModalClose}
        handleSubmit={handleDelete}
        okText={t("MODAL_CONFIRM")}
        cancelText={t("MODAL_CANCEL")}
      />
    </JumboCardQuick>
  );
};
/* Todo scrollHeight prop define */
export default NoteList;
