import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useDispatch, useSelector } from "react-redux";
import {
  delSearchCondition,
  searchNotes,
  setSearchCondition,
} from "app/redux/actions/search";
import { debounce } from "lodash";
import SearchFilter from "app/shared/SearchFilter";
import DatePickerModal from "app/shared/DatePickerModal";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { styled } from "@mui/material/styles";
import { uploadFile } from "app/redux/actions/note";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import useAlert from "app/hooks/useAlert";
import { noteTypeDict } from "app/utils/constants/noteType";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";
const HomeHeader = () => {
  const { t } = useTranslation();

  const smMatch = useMediaQuery("(max-width:575px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorAlert, showSuccessAlert } = useAlert();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [TypePickerAnchorEl, setTypePickerAnchorEl] = useState(null);
  const [updStatus, setUpdStatus] = useState("INIT");
  const [keyword, setKeyword] = useState("");

  const user = useSelector(({ app }) => app.user);

  const config = useSelector(({ search }) => search.getIn(["config"]));

  const handleChange = e => {
    const newKeyword = e.target.value;

    setKeyword(newKeyword);

    if (newKeyword.length >= 2 || !newKeyword.length) {
      debounceSearch(newKeyword);
    }
  };

  const debounceSearch = debounce(keyword => {
    dispatch(searchNotes({ keyword }));
  }, 1000);

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const handleDateRangeSubmit = (start_date, end_date) => {
    const params = {
      start_date,
      end_date,
      keyword,
      page: 1,
      type: config.type,
    };
    if (!config.searchCondition.includes("date_range")) {
      dispatch(setSearchCondition("date_range"));
    }
    dispatch(searchNotes(params));
  };

  const handleDelDateRange = () => {
    const params = {
      start_date: "",
      end_date: "",
      keyword,
      page: 1,
      type: config.type,
    };
    dispatch(delSearchCondition("date_range"));
    dispatch(searchNotes(params));
  };

  const handleTypeChange = type => {
    const params = {
      type,
      keyword,
      page: 1,
      start_date: config.start_date,
      end_date: config.end_date,
    };
    setTypePickerAnchorEl(null);
    dispatch(searchNotes(params));
  };

  const handleDelType = () => {
    const params = {
      type: "",
      keyword,
      page: 1,
      start_date: config.start_date,
      end_date: config.end_date,
    };
    dispatch(delSearchCondition("type"));
    if (config.type) {
      dispatch(searchNotes(params));
    }
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];
    setUpdStatus("UPLOADING");
    const res = await dispatch(uploadFile(file, user.id));
    if (!res) {
      setUpdStatus("FAIL");
      showErrorAlert("上傳失敗，請重新上傳");
      setTimeout(() => setUpdStatus("INIT"), 3000);
      return;
    }
    setUpdStatus("SUCCESS");
    showSuccessAlert("上傳成功，即將跳轉至任務進度頁面");
    navigate("/note/task");
  };

  const UploadButton = ({ handleFileChange, status }) => {
    const statusDict = {
      INIT: {
        text: "檔案上傳",
        BtnIcon: <CloudUploadIcon />,
        color: "info",
      },
      UPLOADING: {
        text: "上傳中",
        BtnIcon: <CircularProgress size={16} />,
        color: "info",
      },
      SUCCESS: {
        text: "上傳成功",
        BtnIcon: <DoneIcon />,
        color: "success",
      },
      FAIL: {
        text: "上傳失敗",
        BtnIcon: <ErrorIcon />,
        color: "error",
      },
    };

    const VisuallyHiddenInput = styled("input")({
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: 1,
      overflow: "hidden",
      position: "absolute",
      bottom: 0,
      left: 0,
      whiteSpace: "nowrap",
      width: 1,
    });

    return (
      <Button
        size="small"
        variant="contained"
        color={statusDict[status].color}
        disabled={status === "UPLOADING"}
        component="label"
        startIcon={statusDict[status].BtnIcon}>
        {statusDict[status].text}
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
    );
  };

  return (
    <Div sx={{ width: "100%" }}>
      {!smMatch && (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="flex-end"
          gap={1}
          mb={2}>
          {user?.provider === "email" && user?.role === "admin" && (
            <Button
              size="small"
              variant="contained"
              color="info"
              startIcon={<GroupIcon />}
              onClick={() => navigate("/private/user/management")}>
              使用者管理
            </Button>
          )}
          <UploadButton
            handleFileChange={handleFileChange}
            status={updStatus}
          />
          <Button
            size="small"
            variant="contained"
            color="info"
            startIcon={<AssignmentIcon />}
            onClick={() => navigate("/note/task")}>
            任務進度
          </Button>
          <Button
            size="small"
            variant="contained"
            color="info"
            startIcon={<SettingsIcon />}
            onClick={() => navigate("/settings")}>
            使用者設定
          </Button>
        </Stack>
      )}
      <Div
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: "0 0.5rem 1.25rem rgba(115, 82, 199, 0.175);",
        }}>
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "8px 10px",
            borderRadius: 2,
          }}>
          {smMatch && (
            <img
              src="/images/logo-main.png"
              alt="logo"
              style={{ width: "50px", height: "50px" }}
            />
          )}

          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              px: 1,
            }}>
            <SearchIcon sx={{ marginRight: 1 }} />
            <TextField
              size="small"
              sx={{ width: "100%", mr: 2, ml: 1 }}
              value={keyword}
              onChange={handleChange}
            />
            <SearchFilter
              onDateRangeOpen={handleDatePickerOpen}
              onTypeSubmit={() => dispatch(setSearchCondition("type"))}
            />
          </Div>
        </Div>

        {config.searchCondition.length > 0 && (
          <Stack
            width="100%"
            direction="row"
            flexWrap="wrap"
            gap={2}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ pb: 1, px: 2 }}>
            {config.searchCondition.map((condition, index) => {
              const needDivider =
                index < config.searchCondition.length &&
                index !== config.searchCondition.length - 1;

              let conditionElement = null;

              if (condition === "date_range") {
                conditionElement = (
                  <Stack
                    key={`condition-${condition}`}
                    direction="row"
                    alignItems="center"
                    gap={1}>
                    <IconButton onClick={handleDelDateRange}>
                      <CloseIcon color="primary" sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ mr: 1, mb: 0 }}>
                      時間區間：
                    </Typography>
                    <Chip
                      label={`${config.start_date} - ${config.end_date}`}
                      color="primary"
                      variant="outlined"
                      component="button"
                      onClick={handleDatePickerOpen}
                    />
                  </Stack>
                );
              }

              if (condition === "type") {
                conditionElement = (
                  <Stack
                    key={`condition-${condition}`}
                    direction="row"
                    alignItems="center"
                    gap={1}>
                    <IconButton onClick={handleDelType}>
                      <CloseIcon color="primary" sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Typography variant="h5" component="h2" sx={{ mb: 0 }}>
                      類型：
                    </Typography>
                    <Chip
                      label={
                        !config.type ? t("ALL") : t(noteTypeDict[config.type])
                      }
                      color="primary"
                      variant="outlined"
                      component="button"
                      onClick={e => setTypePickerAnchorEl(e.currentTarget)}
                    />
                    <Menu
                      open={Boolean(TypePickerAnchorEl)}
                      onClose={() => setTypePickerAnchorEl(null)}
                      anchorEl={TypePickerAnchorEl}>
                      <MenuItem onClick={() => handleTypeChange("")}>
                        全部
                      </MenuItem>
                      {Object.keys(noteTypeDict).map(type => (
                        <MenuItem
                          key={type}
                          onClick={() => handleTypeChange(type)}>
                          {t(noteTypeDict[type])}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                );
              }

              // 返回條件元素和可能的分隔線
              return (
                <React.Fragment key={`fragment-${condition}`}>
                  {conditionElement}
                  {needDivider && <Divider orientation="vertical" flexItem />}
                </React.Fragment>
              );
            })}
          </Stack>
        )}
      </Div>
      <DatePickerModal
        open={isDatePickerOpen}
        startDate={config.start_date}
        endDate={config.end_date}
        handleClose={handleDatePickerClose}
        handleSubmit={(start_date, end_date) =>
          handleDateRangeSubmit(start_date, end_date)
        }
      />
    </Div>
  );
};

export default HomeHeader;
