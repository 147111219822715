import { combineReducers } from "redux";
import authReducer from "./auth";
import noteReducer from "./note";
import labelReducer from "./label";
import searchReducer from "./search";
import chatgptReducer from "./chat";
import subscriptionReducer from "./subscription";
import taskReducer from "./task";
import userReducer from "./user";

const exportReducers = history => {
  return combineReducers({
    app: authReducer,
    note: noteReducer,
    label: labelReducer,
    search: searchReducer,
    chatgpt: chatgptReducer,
    subscription: subscriptionReducer,
    task: taskReducer,
    user: userReducer,
  });
};

export default exportReducers;
