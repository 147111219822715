import Immutable from "immutable";
import {
  DEL_SEARCH_CONDITION,
  FCH_NOTE_DONE,
  FCH_TASKLIST_DONE,
  FCH_VIEW_NOTE_DONE,
  REFRESH_NOTE_STATUS_DONE,
  SEARCH_NOTES,
  SEARCH_NOTES_DONE,
  SET_SEARCH_CONDITION,
} from "../types/search";
import {
  DEL_NOTE_DONE,
  DEL_NOTE_LABEL_DONE,
  UPDATE_NOTE_CONTENT_DONE,
  UPDATE_NOTE_LABEL_DONE,
  UPDATE_NOTE_PASSWORD_DONE,
  UPDATE_NOTE_TITLE_DONE,
  UPDATE_NOTE_URL_DONE,
  MERGE_NOTE_DONE,
  DEL_BATCH_NOTE_DONE,
  SUMMARY_NOTE_DONE,
  TRANSLATE_NOTE_DONE,
  CNG_NOTE_VIEW,
  CLEAR_NOTE,
  RECEIVE_STREAM_DATA,
  SEND_STREAM_REQUEST,
} from "../types/note";
import { FCH_NOTELIST_DONE } from "../types/search";
import { CREATE_LABEL_DONE, FCH_LABELLIST_DONE } from "../types/label";
import { DEL_NOTE_STATUS_DONE } from "../types/task";

const INIT_STATE = Immutable.OrderedMap({
  isSearching: false,
  results: {
    notes: [],
    note: null,
    labels: [],
    task: [],
  },
  total_count: 0,
  config: {
    keyword: "",
    start_date: "",
    end_date: "",
    page: 1,
    type: "",
    searchCondition: [],
  },
});

const searchReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_NOTES: {
      return _.set("isSearching", true);
    }
    case FCH_NOTELIST_DONE:
    case SEARCH_NOTES_DONE: {
      const prevConfig = _.get("config");
      const newConfig = { ...prevConfig, ...payload.config };

      // 轉換並排序筆記數據
      const newNotes = Immutable.fromJS(payload.notes)
        .map(note => note.set("view", "content"))
        .sort((a, b) => b.get("created_at") - a.get("created_at"))
        .toJS();

      // 檢查是否需要重置搜索結果
      const shouldResetResults =
        prevConfig.keyword !== payload.config.keyword ||
        prevConfig.start_date !== payload.config.start_date ||
        prevConfig.end_date !== payload.config.end_date ||
        prevConfig.type !== payload.config.type;

      // 合併筆記數據
      const existingNotes = _.getIn(["results", "notes"]);
      const combinedNotes =
        shouldResetResults || !existingNotes
          ? newNotes
          : [...existingNotes, ...newNotes];

      // 設置新狀態
      return _.setIn(["results", "notes"], combinedNotes)
        .set("total_count", payload.total_count)
        .set(
          "config",
          shouldResetResults ? { ...newConfig, page: 1 } : newConfig
        )
        .set("isSearching", false);
    }
    case FCH_NOTE_DONE:
    case FCH_VIEW_NOTE_DONE:
      return _.setIn(["results", "note"], payload.note);
    case FCH_LABELLIST_DONE:
      return _.setIn(["results", "labels"], payload);
    case CREATE_LABEL_DONE:
      const labels = _.getIn(["results", "labels"]);
      return _.setIn(["results", "labels"], [...labels, payload]);
    case UPDATE_NOTE_CONTENT_DONE:
      const _notes = _.getIn(["results", "notes"]);
      const _newNotes = _notes.map(note => {
        if (note.id === payload.nid) {
          const [[key, value]] = Object.entries(payload.doc);
          return { ...note, [key]: value };
        }
        return note;
      });
      const existNote = _.getIn(["results", "note"]);
      if (existNote) {
        const [key, value] = Object.entries(payload.doc)[0];
        const updatedNote = { ...existNote, [key]: value };
        return _.setIn(["results", "notes"], _newNotes).setIn(
          ["results", "note"],
          updatedNote
        );
      }
      return _.setIn(["results", "notes"], _newNotes);
    case UPDATE_NOTE_LABEL_DONE:
      const notes = _.getIn(["results", "notes"]);
      const newNotes = notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, labels: payload.labels };
        }
        return note;
      });
      return _.setIn(["results", "notes"], newNotes);
    case UPDATE_NOTE_TITLE_DONE:
      const __notes = _.getIn(["results", "notes"]);
      const __newNotes = __notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, title: payload.title };
        }
        return note;
      });

      return _.setIn(["results", "notes"], __newNotes);
    case UPDATE_NOTE_URL_DONE:
      const ___notes = _.getIn(["results", "notes"]);
      const ___newNotes = ___notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, url: payload._url };
        }
        return note;
      });
      return _.setIn(["results", "notes"], ___newNotes);
    case DEL_NOTE_DONE:
      return _.setIn(
        ["results", "notes"],
        _.getIn(["results", "notes"]).filter(note => note.id !== payload)
      ).set("total_count", _.get("total_count") - 1);
    case DEL_BATCH_NOTE_DONE:
      const { ids } = payload;
      const __newNoteList = _.getIn(["results", "notes"]).filter(
        note => !ids.includes(note.id)
      );
      return _.setIn(["results", "notes"], __newNoteList).set(
        "total_count",
        __newNoteList.length
      );
    case SET_SEARCH_CONDITION:
      const _searchCondition = _.getIn(["config", "searchCondition"]);
      return _.setIn(
        ["config", "searchCondition"],
        _searchCondition.includes(payload.condition)
          ? _searchCondition
          : _searchCondition.concat(payload.condition)
      );
    case DEL_SEARCH_CONDITION:
      return _.setIn(
        ["config", "searchCondition"],
        _.getIn(["config", "searchCondition"]).filter(
          condition => condition !== payload.condition
        )
      );
    case DEL_NOTE_LABEL_DONE:
      return _.setIn(
        ["results", "labels"],
        _.getIn(["results", "labels"]).filter(label => label.id !== payload)
      );
    case UPDATE_NOTE_PASSWORD_DONE:
      return _.setIn(["results", "note", "permission"], payload.permission);
    case MERGE_NOTE_DONE:
      const _newNoteList = [..._.getIn(["results", "notes"]), payload.doc].sort(
        (a, b) => b.created_at - a.created_at
      );
      return _.setIn(["results", "notes"], _newNoteList);
    case FCH_TASKLIST_DONE:
      if (payload.page === 1) {
        if (payload.refresh) {
          const _task = _.getIn(["results", "task"]);
          const _newTaskList = Immutable.List(_task)
            .map((note, index) => {
              if (index > 5) return note;
              const newNote = payload.notes.find(_note => _note.id === note.id);
              if (newNote && newNote.status !== note.status) {
                return { ...note, status: newNote.status };
              }
              return note;
            })
            .toJS();

          return _.setIn(["results", "task"], _newTaskList);
        }
        return _.setIn(["results", "task"], payload.notes)
          .set("status_count", payload.status_count)
          .setIn(["config", "status", "page"], payload.page);
      }
      return _.setIn(
        ["results", "task"],
        [..._.getIn(["results", "task"]), ...payload.notes]
      )
        .set("status_count", payload.status_count)
        .setIn(["config", "status", "page"], payload.page);
    case REFRESH_NOTE_STATUS_DONE:
      const newTaskList = _.getIn(["results", "task"]).map(task => {
        if (task.id === payload.note.id) {
          return payload.note;
        }
        return task;
      });
      if (payload.note.status === "DONE") {
        const _notesList = _.getIn(["results", "notes"]);
        const index = _notesList.findIndex(note => note.id === payload.note.id);
        if (index === -1) {
          const _newNoteList = Immutable.List(_notesList).unshift(payload.note);
          return _.setIn(["results", "notes"], _newNoteList).setIn(
            ["results", "task"],
            newTaskList
          );
        }
      }
      return _.setIn(["results", "task"], newTaskList);
    case SUMMARY_NOTE_DONE:
      const _notesList = _.getIn(["results", "notes"]);
      const ___newNoteList = Immutable.List(_notesList)
        .unshift(payload.note)
        .toJS();
      return _.setIn(["results", "notes"], ___newNoteList);
    case TRANSLATE_NOTE_DONE:
      const _existNote = _.getIn(["results", "note"]);
      console.log("payload", payload);
      if (_existNote) {
        return _.setIn(["results", "note", "translate"], payload.note.content);
      }
      return _;
    case DEL_NOTE_STATUS_DONE:
      const _task = _.getIn(["results", "task"]);
      const _newTaskList = _task.filter(task => task.id !== payload.nId);
      return _.setIn(["results", "task"], _newTaskList);
    case CNG_NOTE_VIEW: {
      const { nId, view } = payload;
      const _noteList = _.getIn(["results", "notes"]);

      const _newNoteList = _noteList.map(note => {
        if (note.id === nId) {
          return { ...note, view };
        }
        return note;
      });
      return _.setIn(["results", "notes"], _newNoteList);
    }
    case SEND_STREAM_REQUEST: {
      const { type } = payload;
      const _note = _.getIn(["results", "note"]);
      const existGenerated = _note.generated || [];
      const _newNote = {
        ..._note,
        generated: [...existGenerated, { content: "", type }],
      };
      return _.setIn(["results", "note"], _newNote);
    }
    case RECEIVE_STREAM_DATA: {
      const { chunk } = payload;
      const _note = _.getIn(["results", "note"]);
      const existGenerated = _note.generated || [];
      const lastIndex = existGenerated.length - 1;
      const lastGenerated = existGenerated[lastIndex];
      const updatedGenerated = [...existGenerated];
      updatedGenerated[lastIndex] = {
        ...lastGenerated,
        content: lastGenerated.content + chunk,
      };
      const _newNote = { ..._note, generated: updatedGenerated };
      return _.setIn(["results", "note"], _newNote);
    }
    // case RECEIVE_STREAM_DATA_DONE: {
    //   \

    //   return _.setIn(['results', 'note'], );
    // }
    case CLEAR_NOTE: {
      return _.setIn(["results", "note"], null).setIn(
        ["config", "view"],
        "content"
      );
    }
    default:
      return _;
  }
};

export default searchReducer;
