import {
  SIDEBAR_ANCHOR_POSITIONS,
  SIDEBAR_SCROLL_TYPES,
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "@jumbo/utils/constants/layout";

const layoutConfig = {
  sidebar: {
    open: false,
    hide: false,
    variant: SIDEBAR_VARIANTS.PERSISTENT,
    style: SIDEBAR_STYLES.FULL_HEIGHT,
    view: SIDEBAR_VIEWS.FULL,
    scrollType: SIDEBAR_SCROLL_TYPES.FIXED,
    anchor: SIDEBAR_ANCHOR_POSITIONS.LEFT,
  },
  header: {
    hide: false,
    fixed: true,
  },
  footer: {
    hide: true,
  },
  content: {
    sx: {
      px: 0,
      pb: 0,
      backgroundColor: "#F5F7FA",
      pt: 0,
    },
  },
  root: {
    // paddingBottom: 'env(safe-area-inset-bottom)'
  },
};

export default layoutConfig;
