import React from "react";
import Div from "@jumbo/shared/Div";
import { Link } from "react-router-dom";
import { Typography, Stack } from "@mui/material";

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link to="/" sx={{ textDecoration: "none" }}>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <img
            src="/images/logo-main.png"
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          />
          <Typography
            variant="h2"
            color="primary"
            sx={{ position: "relative", top: 4 }}>
            AIMochi
          </Typography>
        </Stack>
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
