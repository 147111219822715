import React from "react";
import NoteEditPage from "app/pages/Note/Edit";
import NoteViewPage from "app/pages/Note/View";
import NoteTaskPage from "app/pages/Note/Task";

const noteRoutes = [
  {
    path: "/note/edit/:noteId",
    element: <NoteEditPage />,
  },
  {
    path: "/note/view/:noteId",
    element: <NoteViewPage />,
  },
  {
    path: "/note/task",
    element: <NoteTaskPage />,
  },
];

export default noteRoutes;
