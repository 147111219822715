export const CREATE_NOTE = "NOTE/CREATE_NOTE";
export const CREATE_NOTE_DONE = "NOTE/CREATE_NOTE_DONE";

export const UPDATE_NOTE_CONTENT = "NOTE/UPDATE_NOTE_CONTENT";
export const UPDATE_NOTE_CONTENT_DONE = "NOTE/UPDATE_NOTE_CONTENT_DONE";

export const UPDATE_NOTE_LABEL = "NOTE/UPDATE_NOTE_LABEL";
export const UPDATE_NOTE_LABEL_DONE = "NOTE/UPDATE_NOTE_LABEL_DONE";

export const UPDATE_NOTE_TITLE = "NOTE/UPDATE_NOTE_TITLE";
export const UPDATE_NOTE_TITLE_DONE = "NOTE/UPDATE_NOTE_TITLE_DONE";

export const UPDATE_NOTE_URL = "NOTE/UPDATE_NOTE_URL";
export const UPDATE_NOTE_URL_DONE = "NOTE/UPDATE_NOTE_URL_DONE";

export const UPDATE_NOTE_PASSWORD = "NOTE/UPDATE_NOTE_PASSWORD";
export const UPDATE_NOTE_PASSWORD_DONE = "NOTE/UPDATE_NOTE_PASSWORD_DONE";

export const DEL_NOTE = "NOTE/DEL_NOTE";
export const DEL_NOTE_DONE = "NOTE/DEL_NOTE_DONE";

export const DEL_BATCH_NOTE = "NOTE/DEL_BATCH_NOTE";
export const DEL_BATCH_NOTE_DONE = "NOTE/DEL_BATCH_NOTE_DONE";

export const DEL_NOTE_LABEL = "NOTE/DEL_NOTE_LABEL";
export const DEL_NOTE_LABEL_DONE = "NOTE/DEL_NOTE_LABEL_DONE";

export const MERGE_NOTE = "NOTE/MERGE_NOTE";
export const MERGE_NOTE_DONE = "NOTE/MERGE_NOTE_DONE";

export const CLEAR_STATUS = "NOTE/CLEAR_STATUS";

export const SEL_NOTE = "NOTE/SEL_NOTE";
export const CLEAR_NOTES = "NOTE/CLEAR_NOTES";

export const CNG_LIST_VIEW = "NOTE/CNG_LIST_VIEW";

export const SHARE_NOTE = "NOTE/SHARE_NOTE";

export const CNG_PWD_MODAL = "NOTE/CNG_PWD_MODAL";

export const UPD_FILE = "NOTE/UPD_FILE";
export const UPD_FILE_DONE = "NOTE/UPD_FILE_DONE";

export const SUMMARY_NOTE = "NOTE/SUMMARY_NOTE";
export const SUMMARY_NOTE_DONE = "NOTE/SUMMARY_NOTE_DONE";

export const TRANSLATE_NOTE = "NOTE/TRANSLATE_NOTE";
export const TRANSLATE_NOTE_DONE = "NOTE/TRANSLATE_NOTE_DONE";

export const POLISH_NOTE = "NOTE/POLISH_NOTE";
export const POLISH_NOTE_DONE = "NOTE/POLISH_NOTE_DONE";

export const CLEAR_NOTE = "NOTE/CLEAR_NOTE";

export const CNG_NOTE_VIEW = "NOTE/CNG_NOTE_VIEW";

export const SEND_STREAM_REQUEST = "NOTE/SEND_STREAM_REQUEST";
export const SEND_STREAM_REQUEST_DONE = "NOTE/SEND_STREAM_REQUEST_DONE";

export const RECEIVE_STREAM_DATA = "NOTE/RECEIVE_STREAM_DATA";
export const RECEIVE_STREAM_DATA_DONE = "NOTE/RECEIVE_STREAM_DATA_DONE";

export const STOP_STREAM_REQUEST = "NOTE/STOP_STREAM_REQUEST";

export const DOWNLOAD_NOTE = "NOTE/DOWNLOAD_NOTE";
export const DOWNLOAD_NOTE_DONE = "NOTE/DOWNLOAD_NOTE_DONE";

export const EXPORT_TO_NOTION = "USER/EXPORT_TO_NOTION";
export const EXPORT_TO_NOTION_DONE = "USER/EXPORT_TO_NOTION_DONE";
