import Div from "@jumbo/shared/Div";
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import {
  delNote,
  downloadMergedSrtNote,
  formatDownloadContent,
  downloadSentenceSrtNote,
  downloadTXTNote,
  updNotePassword,
  exportToNotion,
} from "app/redux/actions/note";
import ConfirmModal from "app/shared/ConfirmModal";
import useAlert from "app/hooks/useAlert";
import ShareNoteModal from "app/shared/ShareNoteModal";
import DownloadIcon from "@mui/icons-material/Download";
import { convertSrtToSentence, mergeSrt } from "app/utils/downloadDoc";

const status = {
  DONE: {
    text: "已儲存",
    color: "#475259",
  },
  SAVING: {
    text: "儲存中...",
    color: "#475259",
  },
};

const NotePageHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const isMdMatch = useMediaQuery(theme => theme.breakpoints.up("md"));

  const isViewPage = window.location.pathname.includes("/view");
  const isSettingPage = window.location.pathname.includes("/settings");
  const isSubscriptionPage = window.location.pathname.includes("/subscription");
  const isNoteStatus = window.location.pathname.includes("/note/task");

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const saveStatus = useSelector(({ note }) => note.get("saveStatus"));
  const userId = useSelector(({ app }) => app.user.id);

  const isAndroid = /android/i.test(navigator.userAgent);

  const noteView = useSelector(({ search }) =>
    search.getIn(["config", "view"])
  );

  const noteId = window.location.pathname.split("/").pop();
  const note = useSelector(({ search }) => search.getIn(["results", "note"]));

  const handleGoBack = () => {
    navigate("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(delNote(userId, noteId));
    navigate("/");
    handleClose();
    return showSuccessAlert("已成功刪除文章");
  };

  const downloadHandlers = {
    txt: downloadTXTNote,
    sentenceSrt: downloadSentenceSrtNote,
    mergedSrt: downloadMergedSrtNote,
  };

  const contentHandlers = {
    txt: content => content,
    sentenceSrt: convertSrtToSentence,
    mergedSrt: mergeSrt,
  };

  const downloadNote = (content, fileType) => {
    let type = "txt";
    let mimeType = "text/plain;charset=utf-8";

    if (fileType === "plain") type = "srt";

    const file = new Blob([content], {
      type: mimeType,
    });

    const downloadUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.target = "_blank";
    link.download = `note.${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  };

  const handleDownloadTxt = async type => {
    const content = await dispatch(formatDownloadContent(noteId, type));
    if (isMdMatch || isAndroid) {
      downloadNote(content, type);
      handleCloseDownloadMenu();
      return showSuccessAlert("下載成功");
    }

    // const content = contentHandlers[type](note.content);
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const file = new File([blob], `${note.title}.txt`, {
      type: "text/plain;charset=utf-8",
    });

    const shareData = { title: note.title, text: content, files: [file] };

    if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
        console.log("Successfully shared");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
    handleCloseDownloadMenu();
  };

  const handleDownload = e => {
    e.preventDefault();
    handleOpenDownloadMenu(e);
  };

  const handleShare = (nId, password, permission) => {
    dispatch(updNotePassword(userId, nId, password, permission));
  };

  const handleOpenDownloadMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
  };

  const handleExportToNotion = async () => {
    handleCloseDownloadMenu();
    const success = await dispatch(exportToNotion(noteId));
    if (!success) {
      navigate("/settings");
      showErrorAlert("請先至輸出管道右上角綁定 Notion 帳號");
    } else {
      showSuccessAlert("已成功匯出到 Notion");
    }
  };

  const openDownloadMenu = Boolean(anchorEl);

  if (!note) return null;

  return (
    <Div
      id="note-page-header"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}>
      {saveStatus && (
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            bottom: { md: -60, xs: -50 },
            left: "50%",
            transform: "translateX(-50%)",
            color: status[saveStatus].color,
          }}>
          {status[saveStatus].text}
        </Typography>
      )}

      <IconButton
        onClick={handleGoBack}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
          color: "#475259",
          width: { md: "3.5rem", xs: "2.5rem" },
          height: { md: "3.5rem", xs: "2.5rem" },
          mr: 1.5,
        }}>
        <ArrowBackIosNewIcon
          sx={{
            fontSize: { md: "24px", xs: "22px" },
            cursor: "pointer",
            color: "#475259",
          }}
          component="svg"
        />
      </IconButton>

      {!isSettingPage && !isSubscriptionPage && (
        <Stack
          id="ad-container"
          flexDirection="row"
          alignItems="center"
          width="100%"
          justifyContent={{ md: "flex-end", xs: "space-around" }}>
          <Stack flexDirection="column">
            <Typography variant="h3" color="#fff" sx={{ mb: 0.5 }}>
              AI<strong>Mochi</strong>
              <span style={{ color: "#2FAEC6" }}>.</span>
            </Typography>
            <Typography
              variant="h6"
              color="#E7D811"
              sx={{
                fontWeight: 700,
                mb: { md: 0.2, xs: 0.4 },
                fontSize: { md: "1.5rem", xs: "0.6rem" },
              }}>
              分享給還在聽打逐字稿的同事們！
            </Typography>
            <Typography
              variant="body1"
              component="span"
              color="#fff"
              sx={{ fontSize: { md: "1rem", xs: "0.6rem" } }}>
              AI語音轉文字，
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontSize: { md: "1rem", xs: "0.5rem" },
                  color: "#E7D811",
                  padding: "3px 0px",
                  borderBottom: {
                    md: "2px solid #e7d811",
                    xs: "1px solid #e7d811",
                  },
                }}>
                自動完成會議記錄
              </Typography>
              。
            </Typography>
          </Stack>
          {!isViewPage && !isNoteStatus && (
            <Stack flexDirection="row" alignItems="center" gap={0.5}>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://liff.line.me/1645278921-kWRPP32q/?accountId=aimochi",
                    "_blank"
                  )
                }
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
                  color: "#475259",
                  width: { md: "3.5rem", xs: "2.5rem" },
                  height: { md: "3.5rem", xs: "2.5rem" },
                }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { md: 18, xs: 11 },
                    position: "relative",
                    top: 2,
                  }}>
                  安裝
                </Typography>
              </IconButton>
              <IconButton
                onClick={handleDownload}
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
                  color: "#475259",
                  width: { md: "3.5rem", xs: "2.5rem" },
                  height: { md: "3.5rem", xs: "2.5rem" },
                }}>
                <DownloadIcon
                  sx={{
                    fontSize: { md: "36px", xs: "30px" },
                    transform: "scaleX(-1)",
                    cursor: "pointer",
                    color: "#475259",
                  }}
                />
              </IconButton>
              {note.source.type !== "audio" && (
                <Menu
                  sx={{ mt: 1 }}
                  open={openDownloadMenu}
                  onClose={handleCloseDownloadMenu}
                  anchorEl={anchorEl}>
                  <MenuItem onClick={() => handleDownloadTxt("markdown")}>
                    下載筆記
                  </MenuItem>
                  <MenuItem onClick={() => handleExportToNotion()}>
                    匯出至 Notion
                  </MenuItem>
                </Menu>
              )}
              {note.source.type === "audio" && (
                <Menu
                  sx={{ mt: 1 }}
                  open={openDownloadMenu}
                  onClose={handleCloseDownloadMenu}
                  anchorEl={anchorEl}>
                  <MenuItem onClick={() => handleDownloadTxt("plain")}>
                    逐字稿
                  </MenuItem>
                  <MenuItem onClick={() => handleDownloadTxt("captions_merge")}>
                    合併格式
                  </MenuItem>
                  <MenuItem onClick={() => handleDownloadTxt("captions_line")}>
                    逐句格式
                  </MenuItem>
                  <MenuItem onClick={() => handleExportToNotion()}>
                    匯出至 Notion
                  </MenuItem>
                </Menu>
              )}
              <ShareNoteModal
                noteId={noteId}
                updNotePassword={handleShare}
                permission={note.permission}
              />
            </Stack>
          )}
        </Stack>
      )}

      <ConfirmModal
        isOpen={open}
        title="警告"
        content="確定要刪除這一篇文章？"
        handleClose={handleClose}
        handleSubmit={handleDelete}
      />
    </Div>
  );
};

export default NotePageHeader;
