import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import useAlert from "app/hooks/useAlert";
import { sendRedeemCode } from "app/redux/actions/auth";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../ConfirmModal";
import { cancelSubscription } from "app/redux/actions/subscription";

const SubscriptionCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [redeemCode, setRedeemCode] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedSubId, setSelectedSubId] = useState("");

  const user = useSelector(({ app }) => app.user);

  const records = useSelector(({ subscription }) =>
    subscription.get("records")
  );

  const planDict = {
    basic: "基本",
    premium: "高級",
  };

  const handleRedeemCode = async () => {
    const success = await dispatch(sendRedeemCode(user.id, redeemCode.trim()));
    if (!success) {
      return showErrorAlert(t("REDEEM_CODE_FAIL"));
    }
    setRedeemCode("");
    return showSuccessAlert(t("REDEEM_CODE_SUCCESS"));
  };

  const handleCancelModelOpen = sub_id => () => {
    setSelectedSubId(sub_id);
    setOpen(true);
  };

  const handleCancelModelClose = () => {
    setSelectedSubId("");
    setOpen(false);
  };

  const handleCancelSubscription = () => {
    dispatch(cancelSubscription(user.id, selectedSubId));
    setSelectedSubId("");
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ py: 3, px: 3 }}>
        <Typography variant="h2" sx={{ fontWeight: "bold", mb: 4 }}>
          {t("PLAN_CONTENT")}
        </Typography>

        <Grid container spacing={6}>
          <Grid container item xs={12}>
            <Grid item xs={5} md={2} sx={typoStyle}>
              <Typography variant="subtitle2" fontSize={18}>
                {t("PLAN")}
              </Typography>
            </Grid>
            <Grid item xs={4} md={1} sx={typoStyle}>
              <Typography variant="subtitle2" fontSize={18} color="green">
                {user.plan ? planDict[user.plan] : t("PLAN_FREE")}
              </Typography>
            </Grid>
            <Grid item xs={3} md={9}>
              <Button
                variant="contained"
                sx={{ fontSize: { xs: 12, md: 14 } }}
                onClick={() => navigate("/subscription")}>
                {t("PLAN_UPGRADE")}
              </Button>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={5} md={2} sx={typoStyle}>
              <Typography variant="subtitle2" fontSize={18}>
                {t("REDEEM_CODE")}
              </Typography>
            </Grid>
            <Grid item xs={4} md={1} sx={typoStyle}>
              <TextField
                sx={{ width: "80%" }}
                size="small"
                variant="outlined"
                onChange={e => setRedeemCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={3} md={9}>
              <Button
                variant="contained"
                sx={{ fontSize: { xs: 12, md: 14 } }}
                onClick={handleRedeemCode}>
                {t("REDEEM_CODE_BTN")}
              </Button>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6} md={4} sx={{ ...typoStyle }}>
              <Typography variant="subtitle2" fontSize={18}>
                {t("SUBSCRIPTION_RECORD")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 4 }}>
              <TableContainer component="div" sx={{ border: "1px solid #ccc" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("PLAN")}</TableCell>
                      <TableCell>{t("SUBSCRIPTION_TIME")}</TableCell>
                      <TableCell>{t("EXPIRATION_TIME")}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {records && records.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3}>{t("NO_RECORD")}</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {records && records.length > 0 && (
                    <TableBody>
                      {records.slice(0, 5).map((sub, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{planDict[sub.plan]}</TableCell>
                          <TableCell>
                            {moment
                              .unix(sub.created_at)
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            {moment
                              .unix(sub.created_at)
                              .add(1, "years")
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </TableCell>
                          <TableCell>
                            <Link
                              variant="text"
                              sx={{
                                fontSize: 12,
                                cursor: "default",
                              }}
                              component="p"
                              onClick={handleCancelModelOpen(sub.id)}>
                              {t("CANCEL_SUBSCRIPTION")}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <ConfirmModal
        isOpen={open}
        onClose={handleCancelModelClose}
        title="警告"
        content="確認取消訂閱？"
        handleClose={handleCancelModelClose}
        handleSubmit={handleCancelSubscription}
      />
    </>
  );
};

export default SubscriptionCard;

const typoStyle = {
  fontSize: 18,
  display: "flex",
  alignItems: "center",
};
