import { Button, Stack } from "@mui/material";
import { userLogout } from "app/redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Loading from "app/shared/Loading";
import SubscriptionCard from "app/shared/Card/SubscriptionCard";
import UserSettingCard from "app/shared/Card/UserSettingCard";
import ChannelSettingCard from "app/shared/Card/ChannelSettingCard";
import ExportSettingCard from "app/shared/Card/ExportSettingCard";

const SettingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLogout());
  };

  const user = useSelector(({ app }) => app.user);

  if (Object.keys(user).length === 0) return <Loading />;

  return (
    <Stack sx={{ padding: "16px" }} gap={2}>
      <UserSettingCard />
      <ChannelSettingCard />
      <ExportSettingCard />
      <SubscriptionCard />
      <Button
        onClick={handleLogout}
        variant="contained"
        sx={{
          my: { xs: 3, md: 6 },
          mb: { xs: 12, md: 6 },
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          width: { xs: "100%", md: "10%" },
        }}>
        {t("LOGOUT")}
      </Button>
    </Stack>
  );
};

export default SettingPage;
