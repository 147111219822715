import moment from "moment";
import * as __ from "../types/auth";
import { getRequest, postRequest } from "app/services";
import i18n from "app/config/i18n";

export const lineLogin = () => {
  window.open("/auth/login", "_self");
  return { type: __.LINE_LOGIN };
};

export const lineLoginSuccess = token => {
  localStorage.setItem("AIMochi", JSON.stringify({ token }));
  return dispatch => {
    dispatch({ type: __.LINE_LOGIN_SUCCESS, payload: token });
    dispatch(fchUser());
  };
};

export const emailLogin = (email, password) => {
  return async dispatch => {
    dispatch({ type: __.EMAIL_LOGIN });
    try {
      const res = await postRequest("/auth/email_password/login", {
        email,
        password,
      });
      if (res.data && res.data.payload.token) {
        localStorage.setItem(
          "AIMochi",
          JSON.stringify({ token: res.data.payload.token })
        );
        window.location.href = `/?token=${res.data.payload.token}`;
      } else {
        dispatch({ type: __.LOGIN_FAILURE, payload: "Invalid credentials" });
      }
    } catch (error) {
      dispatch({
        type: __.LOGIN_FAILURE,
        payload:
          error?.response?.data?.payload?.message || "Invalid credentials",
      });
    }
  };
};

export const emailSignup = (email, password) => {
  return async dispatch => {
    dispatch({ type: __.EMAIL_SIGNUP });
    try {
      const res = await postRequest("/auth/email_password/signup", {
        email,
        password,
      });
      if (res.data && res.data.payload.token) {
        localStorage.setItem(
          "AIMochi",
          JSON.stringify({ token: res.data.payload.token })
        );
        window.location.href = `/?token=${res.data.payload.token}`;
      } else {
        dispatch({ type: __.SIGNUP_FAILURE, payload: "Signup failed" });
      }
    } catch (error) {
      dispatch({
        type: __.SIGNUP_FAILURE,
        payload: error?.response?.data?.payload?.message || "Signup failed",
      });
    }
  };
};

export const fchUser = () => {
  return async dispatch => {
    dispatch({ type: __.FCH_USER });
    const storage = localStorage.getItem("AIMochi");
    const token = JSON.parse(storage).token;
    const userData = JSON.parse(atob(token.split(".")[1]));
    const id = userData?.id;
    if (moment().unix() > userData.exp) {
      return dispatch(userLogout());
    }
    try {
      const res = await getRequest(`/api/v1/users/${id}`);
      i18n.changeLanguage(res.data.config.lang);
      dispatch(fchUserDone(res.data));
      return res.data.id;
    } catch (error) {
      console.log(error);
    }
  };
};

export const fchUserDone = payload => {
  return dispatch => {
    dispatch({ type: __.FCH_USER_DONE, payload });
  };
};

export const userLogout = () => {
  localStorage.removeItem("AIMochi");
  window.location.href = "/user/login";
  return { type: __.USER_LOGOUT };
};

export const updLang = lang => {
  return dispatch => {
    dispatch({ type: __.UPD_LANG });
    i18n.changeLanguage(lang);
    dispatch(updLangDone(lang));
  };
};

export const updLangDone = lang => {
  return dispatch => {
    dispatch({ type: __.UPD_LANG_DONE, payload: lang });
  };
};

export const updContentLang = lang => {
  return dispatch => {
    dispatch({ type: __.UPD_CONTENT_LANG });

    dispatch(updContentLangDone(lang));
  };
};

export const updContentLangDone = lang => {
  return dispatch => {
    dispatch({ type: __.UPD_CONTENT_LANG_DONE, payload: lang });
  };
};

export const getToken = id => {
  return async dispatch => {
    try {
      dispatch({ type: __.FCH_TOKEN });
      const res = await postRequest("/auth/token", { id });
      console.log(res);
      dispatch({ type: __.FCH_TOKEN_DONE, payload: { token: res.data.token } });
    } catch (error) {
      console.log(error);
    }
  };
};

export const sendRedeemCode = (uId, code) => {
  return async dispatch => {
    try {
      dispatch({ type: __.REDEEM_CODE });
      const res = await postRequest(`/api/v1/users/${uId}/_redeem`, {
        serial_number: code,
      });
      if (res.status >= 400) {
        return false;
      }
      dispatch(sendRedeemCodeDone());
      return true;
    } catch (error) {
      console.log(error);
    }
  };
};

export const sendRedeemCodeDone = () => {
  return dispatch => {
    dispatch(fchUser());
    return { type: __.REDEEM_CODE_DONE };
  };
};

export const forgetPassword = email => {
  return async dispatch => {
    const url = "/auth/email_password/forget";

    dispatch({ type: __.FORGET_PASSWORD });
    const res = await postRequest(url, { email });
    dispatch({ type: __.FORGET_PASSWORD_DONE });
    return res.data.data.success;
  };
};
