import React from "react";
import { Box, Grid, List } from "@mui/material";
import NoteListView from "./NoteListView";
import { useNavigate } from "react-router-dom";

import NoteGridView from "./NoteGridView";
import { useTranslation } from "react-i18next";
import { noteTypeDict } from "app/utils/constants/noteType";

const NoteItemList = ({
  dataSource,
  listView,
  handleSelect,
  selectedNotes,
  handleDelModalOpen,
  labelList,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goEditPage = id => {
    navigate(`/note/edit/${id}`);
  };
  if (listView) {
    return (
      <List>
        {dataSource.map(note => (
          <NoteListView
            note={note}
            key={note.id}
            type={t(noteTypeDict[note.source.type])}
            handleSelect={handleSelect}
            goEditPage={goEditPage}
            selectedNotes={selectedNotes}
            handleDelModalOpen={handleDelModalOpen}
            labelList={labelList}
          />
        ))}
      </List>
    );
  }

  return (
    <Box component="div" sx={{ width: { xs: 340, md: "100%" } }}>
      <Grid container spacing={1} sx={{ ml: 0 }}>
        {dataSource.map(note => (
          <NoteGridView
            note={note}
            key={note.id}
            type={t(noteTypeDict[note.source.type])}
            handleSelect={handleSelect}
            goEditPage={goEditPage}
            selectedNotes={selectedNotes}
            handleDelModalOpen={handleDelModalOpen}
            labelList={labelList}
          />
        ))}
      </Grid>
    </Box>
  );
};
/* Todo tasks prop define */
export default NoteItemList;
