import { generateHTML, generateJSON } from "@tiptap/core";
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Image from "@tiptap/extension-image";
import TLink from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import TurndownService from "turndown";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Blockquote from "@tiptap/extension-blockquote";
import CodeBlock from "@tiptap/extension-code-block";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import Code from "@tiptap/extension-code";
import { marked } from "marked";

const genListType = [
  Document,
  Color,
  Highlight,
  HorizontalRule.configure({
    HTMLAttributes: { class: "novel-editor-hr" },
  }),
  Image.configure({ HTMLAttributes: { class: "novel-editor-image" } }),
  TLink,
  TaskItem,
  TaskList,
  TextStyle,
  Underline,
  Paragraph.configure({ HTMLAttributes: { class: "novel-editor-p" } }),
  Text,
  Bold,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Code,
];

export const genHTML = (note, view) => {
  const contentStr = getNoteContent(note, view);

  // if contentStr is html, return it
  if (testHtml(contentStr)) {
    return contentStr;
  }

  // init jsonObj
  const jsonObj = {
    type: "doc",
    content: [],
  };

  // if contentStr is empty, add a paragraph with text "目前沒有任何內容"
  if (!contentStr) {
    jsonObj.content.push({
      type: "paragraph",
      content: [{ text: " ", type: "text" }],
    });

    return generateHTML(jsonObj, genListType);
  }

  // if contentStr is a valid JSON, use it
  const jsonData =
    contentStr.includes('"type":"doc"') && JSON.parse(note[view]);
  const isJSON = jsonData.hasOwnProperty("type") && jsonData.type === "doc";

  if (!testHtml(contentStr) && !isJSON) {
    return stringToHtml(contentStr);
  }

  JSON.parse(contentStr).content.forEach(c => {
    jsonObj.content.push(c);
  });

  // if note type is image amd is not in the content, add it
  const foundItem = jsonObj.content.find(
    item =>
      item.type === "image" && item.attrs && item.attrs.src === note.fileurl
  );

  if (note.source.type === "image" && !foundItem) {
    const imageObj = {
      type: "image",
      attrs: {
        src: note.fileurl,
        alt: "image",
        title: null,
        HTMLAttributes: {
          class: "novel-editor-image",
        },
      },
    };

    jsonObj.content.push(imageObj);
  }

  return generateHTML(jsonObj, genListType);
};

export const genContent = (note, type) => {
  if (!type || !note) return;

  let content = getNoteContent(note, type);

  if (!content) return "";

  if (!testHtml(content)) {
    content = stringToHtml(content);
    if (note.source?.type === "image") {
      content += `<img src="${note.fileurl}" alt="Note Image" />`;
    }
  }

  const jsonContent = generateJSON(content, genListType);

  const foundItem = jsonContent.content.find(
    item =>
      item.type === "image" && item.attrs && item.attrs.src === note.fileurl
  );

  if (note.source.type === "image" && !foundItem) {
    const imageObj = {
      type: "image",
      attrs: {
        src: note.fileurl,
        alt: "image",
        title: null,
        HTMLAttributes: {
          class: "novel-editor-image",
        },
      },
    };

    jsonContent.content.push(imageObj);
  }

  return jsonContent;
};

export const genTxt = (note, view) => {
  const content = getNoteContent(note, view);
  const file = new Blob([content], {
    type: "text/plain;charset=utf-8",
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = "note.txt";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const genMarkDown = (note, view) => {
  const turndownService = new TurndownService({
    bulletListMarker: "-",
    emDelimiter: "_",
  });
  turndownService.addRule("escapeRule", {
    filter: ["p"],
    replacement: content => content.replace(/(\d+)\\\./, "$1."),
  });

  const content = getNoteContent(note, view);

  const url = `${window.location.origin}${note.fileurl}`;
  const isHtml = testHtml(content);

  let html = isHtml ? content : stringToHtml(content);

  if (note.source?.type === "image") {
    const imageUrl = `<img src="${url}" alt="Note Image" />`;
    html += imageUrl;
  }

  // Try to parse as JSON only if it looks like JSON
  if (isHtml && content.trim().startsWith("{")) {
    try {
      const json = JSON.parse(content);
      html = generateHTML(json, genListType);
    } catch (error) {
      // If JSON parsing fails, use the HTML content as is
      console.warn("Failed to parse JSON:", error);
    }
  }

  return turndownService.turndown(html);
};

export const stringToHtml = str => {
  const isMarkdown = MarkdownValidator(str);
  if (isMarkdown) {
    return marked.parse(str);
  }

  // Remove special tags
  str = str.replace(/<\/?[^>]+(>|$)/g, "");

  // Normalize line endings (convert \r\n to \n)
  str = str.replace(/\r\n/g, "\n");

  // Replace multiple consecutive newlines with a single \n
  str = str.replace(/\n\s*\n/g, "\n");

  // Split into paragraphs
  const paragraphs = str
    .split("\n")
    .map(p => p.trim())
    .filter(p => p.length > 0);

  // Wrap each paragraph in <p> tags
  const html = paragraphs.map(p => `<p>${p}</p>`).join("\n");

  return html;
};

const testHtml = str => {
  // 定義常見的 HTML 標籤
  const commonHtmlTags = [
    "a",
    "abbr",
    "article",
    "aside",
    "b",
    "blockquote",
    "body",
    "br",
    "button",
    "caption",
    "code",
    "col",
    "colgroup",
    "div",
    "dl",
    "dt",
    "dd",
    "em",
    "fieldset",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "head",
    "header",
    "hr",
    "html",
    "i",
    "iframe",
    "img",
    "input",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "meta",
    "nav",
    "ol",
    "option",
    "p",
    "pre",
    "script",
    "section",
    "select",
    "small",
    "span",
    "strong",
    "style",
    "table",
    "tbody",
    "td",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "tr",
    "ul",
  ].join("|");

  const regex = new RegExp(`<(${commonHtmlTags})[^>]*>(.*?)<\\1>`, "gi");

  return regex.test(str);
};

export const getNoteContent = (note, view) => {
  if (Object.keys(note).length === 0 || !view) return;
  if (view === "content" || view === "translate" || view === "summary") {
    return note[view];
  }
  return note.generated[view.split("-")[1]]?.content;
};

export const convertSrtToSentence = async srtContent => {
  let result = "";
  let currentBlock = [];
  const lines = srtContent.split("\n");

  for (const line of lines) {
    const trimmedLine = line.trim();

    // 跳過空行，輸出已收集的文字
    if (trimmedLine === "") {
      if (currentBlock.length > 0) {
        result += currentBlock.join(" ") + "\n";
        currentBlock = [];
      }
      continue;
    }

    // 跳過序號和時間戳
    if (/^\d+$/.test(trimmedLine)) continue;
    if (trimmedLine.includes("-->")) continue;

    // 收集文字行
    currentBlock.push(trimmedLine);
  }

  // 處理最後一個區塊
  if (currentBlock.length > 0) {
    result += currentBlock.join(" ") + "\n";
  }

  return result.trim();
};

export const mergeSrt = async srtContent => {
  let lines = srtContent.split("\n");
  let lastSpeaker = null;
  let lastText = "";
  let results = [];

  for (let line of lines) {
    if (!line.trim()) continue;

    if (/^\d+$/.test(line.trim())) continue;

    if (
      /^\d{2}:\d{2}:\d{2},\d{3}\s-->\s\d{2}:\d{2}:\d{2},\d{3}$/.test(
        line.trim()
      )
    )
      continue;

    const parts = line.split(":");
    if (parts.length >= 2) {
      const speaker = parts[0].trim();
      const dialogue = parts.slice(1).join(":").trim();

      if (lastSpeaker === speaker) {
        if (/[.!?。,，！？]$/.test(lastText)) {
          lastText += ` ${dialogue}`;
        } else {
          lastText += `, ${dialogue}`;
        }
      } else {
        if (lastSpeaker !== null) {
          results.push(`${lastSpeaker}：${lastText}\n`);
        }
        lastSpeaker = speaker;
        lastText = dialogue;
      }
    }
  }

  if (lastSpeaker !== null) {
    results.push(`${lastSpeaker}：${lastText}\n`);
  }

  return results.join("");
};

const MarkdownValidator = text => {
  if (!text || typeof text !== "string") {
    return false;
  }

  // Common Markdown patterns to check for
  const markdownPatterns = [
    /^#+\s+.+$/m, // Headers: # Heading
    /^=+$|^-+$/m, // Alternative headers: ===== or -----
    /\*\*.+?\*\*/, // Bold: **bold**
    /\*.+?\*/, // Italic: *italic*
    /__.+?__/, // Bold: __bold__
    /_.+?_/, // Italic: _italic_
    /~~.+?~~/, // Strikethrough: ~~text~~
    /^\s*[\*\-\+]\s+.+$/m, // Unordered lists: * item or - item or + item
    /^\s*\d+\.\s+.+$/m, // Ordered lists: 1. item
    /^\s*>\s+.+$/m, // Blockquotes: > quote
    /`{1,3}[^`]+`{1,3}/, // Inline code: `code` or code blocks ```code```
    /^\s*```[^`]*```\s*$/m, // Code blocks: ```code```
    /!\[.+?\]\(.+?\)/, // Images: ![alt](url)
    /\[.+?\]\(.+?\)/, // Links: [text](url)
    /^\s*\|.+\|.+\|/m, // Tables: | column | column |
    /^\s*[-:]{3,}\s*\|/m, // Table formatting: ---|---
    /\^\[.+?\]/, // Footnotes: ^[footnote]
    /\[\^.+?\]/, // Reference-style footnotes: [^1]
  ];

  // Check if the text matches any of the Markdown patterns
  for (const pattern of markdownPatterns) {
    if (pattern.test(text)) {
      return true;
    }
  }

  // Check for HTML tags which are allowed in Markdown
  const htmlPattern = /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/i;
  if (htmlPattern.test(text)) {
    return true;
  }

  return false;
};
