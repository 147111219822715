import {
  Card,
  Typography,
  Stack,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  DialogActions,
  Button,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { getRequest } from "app/services";
import { useSelector } from "react-redux";

const ExportSettingCard = () => {
  const { t } = useTranslation();
  const [exportMethods, setExportMethods] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState("notion");

  const user = useSelector(({ app }) => app.user);

  const handleExportModalOpen = () => {
    setExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };

  const handleAddExport = () => {
    // Here you would add the logic to add the export channel
    if (selectedExportType === "notion") {
      window.location.href = `/auth/notion?user_id=${user.id}`;
      setExportModalOpen(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      getRequest(`/api/v1/users/${user.id}/exports`)
        .then(response => {
          setExportMethods(response.data);
        })
        .catch(error => {
          console.error("Error fetching exports:", error);
        });
    }
  }, []);

  return (
    <>
      <Card sx={{ py: 3, px: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={4}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            {t("EXPORTS")}
          </Typography>
          <IconButton color="primary" onClick={handleExportModalOpen}>
            <AddIcon />
          </IconButton>
        </Stack>

        <TableContainer component="div" sx={{ border: "1px solid #ccc" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("EXPORT_TYPE")}</TableCell>
                <TableCell>{t("CREATED_AT")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exportMethods.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2}>{t("NO_EXPORTS")}</TableCell>
                </TableRow>
              ) : (
                exportMethods.map((exportItem, index) => (
                  <TableRow key={index}>
                    <TableCell>{exportItem.type}</TableCell>
                    <TableCell>
                      {moment
                        .unix(exportItem.created_at)
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Dialog open={exportModalOpen} onClose={handleExportModalClose}>
        <DialogTitle>{t("ADD_EXPORT_CHANNEL")}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={{ mt: 1, width: "100%" }}>
            <RadioGroup
              value={selectedExportType}
              onChange={e => setSelectedExportType(e.target.value)}>
              <FormControlLabel
                value="notion"
                control={<Radio />}
                label="Notion"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportModalClose}>{t("CANCEL")}</Button>
          <Button onClick={handleAddExport} variant="contained">
            {t("ADD")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportSettingCard;
