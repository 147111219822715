import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "app/services";

const ChannelSettingCard = () => {
  const { t } = useTranslation();

  const [channels, setChannels] = useState([]);
  const user = useSelector(({ app }) => app.user);

  useEffect(() => {
    if (user.id) {
      getRequest(`/api/v1/users/${user.id}/channels`)
        .then(response => {
          setChannels(response.data);
        })
        .catch(error => {
          console.error("Error fetching channels:", error);
        });
    }
  }, []);

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Typography variant="h2" sx={{ fontWeight: "bold", mb: 4 }}>
        {t("CHANNELS_SETTING")}
      </Typography>

      <TableContainer component="div" sx={{ border: "1px solid #ccc" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("CHANNEL_PROVIDER")}</TableCell>
              <TableCell>{t("CREATED_AT")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3}>{t("NO_CHANNELS")}</TableCell>
              </TableRow>
            ) : (
              channels.map((channel, index) => (
                <TableRow key={index}>
                  <TableCell>{channel.provider}</TableCell>
                  <TableCell>
                    {moment
                      .unix(channel.created_at)
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ChannelSettingCard;

const typoStyle = {
  fontSize: 18,
  display: "flex",
  alignItems: "center",
};
