import { getRequest, patchRequest, postRequest } from "app/services";

import * as __ from "../types/subscription";

export const subscriptionPlan = (formData, uId) => {
  const url = `/api/v1/users/${uId}/subscriptions`;

  return async dispatch => {
    try {
      dispatch({ type: __.SUBSCRIPTION_PLAN });
      const res = await postRequest(url, formData);

      const html = `
        <form method="${res.data.method}" action="${res.data.action}">
          ${res.data.inputs
            .map(
              input => `
            <input name="${input.name}" value="${input.value}">
          `
            )
            .join("")}
          <input type="submit" value="Submit">
        </form>
      `;

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      const form = doc.querySelector("form");

      document.body.appendChild(form);

      form.submit();
      form.remove();
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSubscriptionInfo = uId => {
  const url = `/api/v1/users/${uId}/subscriptions`;

  return async dispatch => {
    dispatch({ type: __.FCH_SUBSCRIPTION_PLAN });
    const subscriptionInfo = await getRequest(url);

    dispatch({
      type: __.FCH_SUBSCRIPTION_PLAN_DONE,
      payload: { records: subscriptionInfo.data },
    });
  };
};

export const cancelSubscription = (uId, sId) => {
  const url = `/api/v1/users/${uId}/subscriptions/${sId}`;

  const body = {
    status: "TERMINATE",
  };

  return async dispatch => {
    try {
      dispatch({ type: __.CANCEL_SUBSCRIPTION });
      await patchRequest(url, body);
      dispatch({ type: __.CANCEL_SUBSCRIPTION_DONE, payload: { id: sId } });
    } catch (error) {
      console.log(error);
    }
  };
};

export const cancelSubscriptionDone = () => {
  return { type: __.CANCEL_SUBSCRIPTION_DONE };
};
