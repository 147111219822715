import React, { Suspense, useState } from "react";
import { IconButton } from "@mui/material";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import { useJumboLayoutSidebar, useJumboSidebarTheme } from "@jumbo/hooks";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { useDispatch, useSelector } from "react-redux";
import Immutable from "immutable";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfirmModal from "app/shared/ConfirmModal";
import { delNote } from "app/redux/actions/note";
import InfiniteScroll from "react-infinite-scroll-component";
import { searchNotes } from "app/redux/actions/search";
import { noteTypeDict } from "app/utils/constants/noteType";
import useAlert from "app/hooks/useAlert";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showSuccessAlert } = useAlert();

  const [open, setOpen] = useState(false);
  const [nId, setNId] = useState(null);

  const noteList = useSelector(({ search }) =>
    search.getIn(["results", "notes"])
  );

  const total_count = useSelector(({ search }) => search.get("total_count"));
  const userId = useSelector(({ app }) => app.user.id);

  const config = useSelector(({ search }) => search.get("config"));

  const handleFchMore = () => {
    dispatch(
      searchNotes({
        page: config.page + 1,
        keyword: config.keyword,
        start_date: config.start_date,
        end_date: config.end_date,
      })
    );
  };

  if (!noteList) return null;

  const handleOpen = (e, id) => {
    e.stopPropagation();
    setNId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setNId(null);
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(delNote(userId, nId));
    navigate("/");
    handleClose();
    return showSuccessAlert("已成功刪除文章");
  };

  const currentDate = moment().format("YYYY-MM-DD");

  // Group notes by formatted date
  const groupedNotes = noteList.reduce((acc, note) => {
    const formattedDate = moment.unix(note.created_at).format("YYYY-MM-DD");
    if (!acc.has(formattedDate)) {
      acc = acc.set(formattedDate, Immutable.List());
    }
    return acc.update(formattedDate, list => list.push(note));
  }, Immutable.Map());

  // Sort dates in descending order
  const sortedDates = groupedNotes
    .keySeq()
    .sort(
      (a, b) => moment(b, "YYYY-MM-DD").unix() - moment(a, "YYYY-MM-DD").unix()
    );

  // Convert to menus format
  const menus = sortedDates.reduce((acc, formattedDate) => {
    const notesForDate = groupedNotes.get(formattedDate);
    const children = notesForDate
      .map(note => {
        let title = note.title;
        if (!note.title)
          title = `${t(noteTypeDict[note.source.type])} - ${moment
            .unix(note.created_at)
            .format("YYYY-MM-DD")}`;
        return {
          id: note.id,
          uri: `/note/edit/${note.id}`,
          label: title,
          type: "nav-item",
          handleOpen: handleOpen,
        };
      })
      .toList();

    let date;

    switch (true) {
      case formattedDate === currentDate:
        date = t("TODAY");
        break;
      case moment(formattedDate, "YYYY-MM-DD").isSame(
        moment().subtract(1, "days"),
        "day"
      ):
        date = t("YESTERDAY");
        break;
      case moment(formattedDate, "YYYY-MM-DD").isSame(
        moment().subtract(7, "days"),
        "day"
      ):
        date = t("SEVEN_DAYS");
        break;
      default:
        date = formattedDate;
    }

    acc = acc.push({
      label: date,
      type: "section",
      children: children.toJS(),
    });

    return acc;
  }, Immutable.List());

  return (
    <Div>
      <SidebarHeader />
      <Div
        id="siderbarDiv"
        sx={{ height: window && window.innerHeight - 80, overflow: "auto" }}>
        <InfiniteScroll
          dataLength={noteList.length}
          hasMore={noteList.length < total_count}
          scrollableTarget="siderbarDiv"
          next={handleFchMore}>
          <Suspense
            fallback={
              <Div
                sx={{
                  display: "flex",
                  minWidth: 0,
                  alignItems: "center",
                  alignContent: "center",
                  px: 3,
                }}>
                <SidebarSkeleton />
              </Div>
            }>
            <JumboVerticalNavbar translate items={menus.toJS()} />
          </Suspense>
        </InfiniteScroll>
      </Div>
      <ConfirmModal
        isOpen={open}
        title="警告"
        content="確定要刪除這一篇文章？"
        handleClose={handleClose}
        handleSubmit={handleDelete}
      />
    </Div>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}>
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          }
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
