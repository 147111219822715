import Div from "@jumbo/shared/Div";
import { IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const BasicHeader = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    let path = "/";
    if (window.location.pathname.includes("/subscription")) path = "/settings";
    navigate(path);
  };

  return (
    <Div
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}>
      <IconButton
        onClick={handleGoBack}
        sx={{
          zIndex: 1000,
          boxShadow: "rgba(182, 149, 149, 0.09) 0px 5px 10px",
          color: "#475259",
          width: { md: "3.5rem", xs: "2.5rem" },
          height: { md: "3.5rem", xs: "2.5rem" },
          mr: 1.5,
        }}>
        <ArrowBackIosNewIcon
          sx={{
            fontSize: { md: "24px", xs: "22px" },
            cursor: "pointer",
            color: "#475259",
          }}
          component="svg"
        />
      </IconButton>

      <Stack
        component="div"
        // sx={{  right: 0 }}
        gap={2}
        width="100%"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="row">
        <Stack flexDirection="column">
          <Typography variant="h2" color="#fff" sx={{ mb: 0.2 }}>
            AI<strong>Mochi</strong>
            <span style={{ color: "#2FAEC6" }}>.</span>
          </Typography>
          <Typography
            variant="h3"
            color="#E7D811"
            sx={{
              fontWeight: 700,
              mb: 0.2,
              fontSize: { md: "1.5rem", xs: "0.8rem" },
            }}>
            分享給還在聽打逐字稿的同事們！
          </Typography>
          <Typography
            variant="body1"
            component="span"
            color="#fff"
            sx={{ fontSize: { md: "1rem", xs: "0.6rem" } }}>
            AI語音轉文字，
            <Typography
              component="span"
              variant="body1"
              sx={{
                fontSize: { md: "1rem", xs: "0.7rem" },
                color: "#E7D811",
                padding: "3px 0px",
                borderBottom: {
                  md: "2px solid #e7d811",
                  xs: "1px solid #e7d811",
                },
              }}>
              自動完成會議記錄
            </Typography>
            。
          </Typography>
        </Stack>
      </Stack>
    </Div>
  );
};

export default BasicHeader;
