import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppLayoutRoutes from "./AppLayoutRoutes";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { fchUser, lineLoginSuccess } from "./redux/actions/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSearchParams } from "react-router-dom";
import { NO_NEED_LOGIN_ROUTES } from "./utils/constants/exceptionRoute";

import "./utils/vConsole"; // show mobile console
import MetaHelmet from "./shared/Helmet";
import { searchNotes } from "./redux/actions/search";
import { fchLabelList } from "./redux/actions/label";
import { getSubscriptionInfo } from "./redux/actions/subscription";
import Loading from "./shared/Loading";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let isInitialized = false;

  const [isLoading, setIsLoading] = useState(true);

  const storage = localStorage.getItem("AIMochi");
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect_path");

  const isNoNeedLogin = NO_NEED_LOGIN_ROUTES.some(route =>
    window.location.pathname.includes(route)
  );

  const token = searchParams.get("token");

  const from = location.state?.from || "/";

  useEffect(() => {
    if (isNoNeedLogin) return;

    if (token) {
      dispatch(lineLoginSuccess(token));
      navigate(redirectPath || from || "/", { replace: true, state: {} });
      return;
    }

    if (!storage && !token) {
      navigate("/user/login", {
        state: { from: location.pathname },
        replace: true,
      });
      return;
    }
  }, [redirectPath, storage, isNoNeedLogin, token, from, location.pathname]);

  useEffect(() => {
    const initApp = async () => {
      if (isInitialized) return;
      isInitialized = true;
      setIsLoading(false);

      try {
        const userId = await dispatch(fchUser());
        await Promise.all([
          dispatch(searchNotes()),
          dispatch(fchLabelList(userId)),
          dispatch(getSubscriptionInfo(userId)),
        ]);
      } catch (error) {
        console.error("初始化失敗:", error);
      }
    };

    if (storage) {
      setIsLoading(true);
      initApp();
      setIsLoading(false);
    }
  }, [storage]);

  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <AppProvider>
      <MetaHelmet />
      <JumboApp activeLayout={config.activeLayout}>
        <JumboTheme init={config.theme}>
          <JumboRTL>
            <JumboDialogProvider>
              <JumboDialog />
              <SnackbarProvider maxSnack={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <AppLayout>
                    <Suspense
                      fallback={
                        <Div
                          sx={{
                            display: "flex",
                            minWidth: 0,
                            alignItems: "center",
                            alignContent: "center",
                            height: "100%",
                          }}>
                          <CircularProgress sx={{ m: "-40px auto 0" }} />
                        </Div>
                      }>
                      <AppLayoutRoutes />
                    </Suspense>
                  </AppLayout>
                </LocalizationProvider>
              </SnackbarProvider>
            </JumboDialogProvider>
          </JumboRTL>
        </JumboTheme>
      </JumboApp>
    </AppProvider>
    // </HelmetProvider>
  );
}

export default App;
