import React from "react";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { IconButton, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import HomeHeader from "./home";
import NotePageHeader from "./note";
import ViewHeader from "./view";
import BasicHeader from "./basic";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const isEditPage = pathname.includes("/edit");
  const isSettingPage = pathname.includes("/settings");
  const isSubscriptionPage = pathname.includes("/subscription");
  const isViewPage = pathname.includes("/view");
  const is404page = pathname.includes("/404");
  const is403page = pathname.includes("/403");
  const isRecordPage = pathname.includes("/record");
  const isNoteStatus = pathname.includes("/note/task");

  const smMatch = useMediaQuery("(max-width:575px)");

  const HeaderType = () => {
    switch (true) {
      case isEditPage:
      case isRecordPage:
        return <NotePageHeader />;
      case isViewPage:
        return <ViewHeader />;
      case isSubscriptionPage:
      case isNoteStatus:
      case isSettingPage:
      case is404page:
      case is403page:
        return <BasicHeader />;
      default:
        return <HomeHeader />;
    }
  };

  return (
    <React.Fragment>
      {!smMatch &&
        !isViewPage &&
        !is404page &&
        !is403page &&
        (sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
          (sidebarOptions.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
            !sidebarOptions?.open)) && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              ml:
                sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                  ? -2
                  : 0,
              mr: 3,
              alignSelf: "center",
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}>
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}
      <HeaderType />
    </React.Fragment>
  );
};

export default Header;
