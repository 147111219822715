const en = {
  translation: {
    // Sider bar
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    SEVEN_DAYS: "Previous 7 Days",
    USER_DATA: "User Data",
    // Home
    NOTE_LIST: "Note list",
    NOTE_SELECTED: "Selected Notes",
    // Setting
    USER_SETTING: "User Setting",
    INTERFACE_LANG: "Interface Language",
    CONTENT_LANG: "Content Language",
    MAGIC_POINT: "Magic Point",
    REF_CODE: "Referral Code",
    LOGOUT: "Logout",
    PLAN_CONTENT: "Plan Content",
    PLAN: "Plan",
    PLAN_FREE: "Free",
    PLAN_STANDARD: "Standard",
    PLAN_PREMIUM: "Premium",
    PLAN_UPGRADE: "Upgrade Plan",
    SUBSCRIPTION_RECORD: "Subscription Record",
    CANCEL_SUBSCRIPTION: "Cancel Subscription",
    SUBSCRIPTION_TIME: "Subscription Time",
    EXPIRATION_TIME: "Expiration Time",
    NO_RECORD: "No Subscription Record",
    REDEEM_CODE: "Redeem Code",
    REDEEM_CODE_BTN: "Redeem",
    CHANNELS_SETTING: "Channels",
    CHANNELS_PROVIDER: "Provider",
    ADD_EXPORT_CHANNEL: "Add Export Channel",
    EXPORTS: "Exports",
    EXPORT_TYPE: "Provider",
    CREATED_AT: "Created At",
    // NOTE
    ALL: "All",
    LINK_NOTE: "Link",
    IMAGE_NOTE: "Image",
    AUDIO_NOTE: "Audio",
    TEXT_NOTE: "Text",
    FILE_NOTE: "File",
    SUMMARY_NOTE: "Summary",
    POLISH_NOTE: "Polish",
    TRANSLATE_NOTE: "Translate",
    NOTE_CREATED_AT: "Created",
    NOTE_TAGS: "Tags",
    NOTE_TAGS_GENERATING: "AI Tags Generating...",
    NOTE_LABELS: "Labels",
    NOTE_LABELS_ADD: "Add +",
    NOTE_URL: "Link",
    NOTE_SHARE: "Share",
    NOTE_TITLE: "Title",
    NOTE_REMAINING_TIMES: "Remaining Times",
    NOTE_TASK_TYPE: "Task Type",
    NOTE_VIEW: "Watch Note",
    DEL_TASK: "Delete Task",
    // CONFIRM MODAL
    MODAL_CONFIRM: "Confirm",
    MODAL_CANCEL: "Cancel",
    MODAL_TITLE_WARNING: "Warning",
    MODAL_DELETE_NOTE: "Are you sure to delete this note?",
    // FOOTER
    FOOTER_HOME: "Home",
    FOOTER_SETTING: "Setting",
    // ALERT
    REDEEM_CODE_SUCCESS: "Redeem Code Success",
    REDEEM_CODE_FAIL: "Redeem Code Failed",
    // COMMON
    TRANSLATE: "Translate",
    POLISH: "Polish",
    SUMMARY: "Summary",
    UPLOAD: "Upload",
    REFRESH: "Refresh",
    MINUTES: "Minutes",
    // OTHER
    NO_NOTES_FOUND: "No Notes Found",
    NO_NOTES_CREATED: "No Notes Created",
  },
};

export default en;
