import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

const SearchFilter = ({ onDateRangeOpen, onTypeSubmit }) => {
  const [anChorEl, setAnChorEl] = useState(null);
  const isMenuOpen = Boolean(anChorEl);

  const handleClick = event => {
    setAnChorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnChorEl(null);
  };

  const handleMenuClick = cb => {
    handleMenuClose();
    cb();
  };

  const itemDict = [
    {
      key: "date",
      name: "時間區間",
      callback: () => handleMenuClick(onDateRangeOpen),
    },
    {
      key: "type",
      name: "類型",
      callback: () => handleMenuClick(onTypeSubmit),
    },
  ];

  return (
    <>
      <IconButton onClick={handleClick} sx={{ backgroundColor: "inherit" }}>
        <TuneIcon />
      </IconButton>
      <Menu anchorEl={anChorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {itemDict.map(item => (
          <MenuItem key={item.key} onClick={item.callback}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SearchFilter;
