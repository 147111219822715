import { useJumboApp } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { getUserList, searchUser } from "app/redux/actions/user";
import Loading from "app/shared/Loading";
import CreateUserModal from "app/shared/Modal/CreateUserModal";
import UserTable from "app/shared/Table/UserTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const UserManagementPage = () => {
  const dispatch = useDispatch();
  const { setActiveLayout } = useJumboApp();
  const navigate = useNavigate();

  const userList = useSelector(({ user }) => user.get("results"));
  const user = useSelector(({ app }) => app.user);

  const handleSearch = e => {
    dispatch(searchUser(e.target.value));
  };

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (!user?.role || user?.role !== "admin") {
        navigate("/");
      }
    }
  }, [user]);

  useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    return () => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
    };
  }, []);

  if (!userList) return <Loading />;

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Div>
      <Div
        id="note-page-header"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          background:
            "linear-gradient(to right, #3E3D92 0%,#3E3D92 86%,#59337C 100%)",
          height: "120px",
          px: { xs: 2, md: 6 },
        }}>
        <IconButton
          onClick={handleGoBack}
          sx={{
            zIndex: 1000,
            boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
            color: "#475259",
            width: { md: "3.5rem", xs: "2.5rem" },
            height: { md: "3.5rem", xs: "2.5rem" },
            mr: 1.5,
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}>
          <ArrowBackIosNewIcon
            sx={{
              fontSize: { md: "24px", xs: "22px" },
              cursor: "pointer",
              color: "#475259",
            }}
            component="svg"
          />
        </IconButton>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <img
            src="/images/logo-main.png"
            alt="logo"
            style={{ width: "64px", height: "64px" }}
          />
          <Typography
            variant="h1"
            color="#fff"
            sx={{ position: "relative", top: 4 }}>
            AIMochi
          </Typography>
        </Box>
      </Div>
      <Div sx={{ p: 4 }}>
        <Card>
          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
              width: "100%",
              px: 4,
              pt: 2,
            }}>
            <Typography variant="h1" sx={{ fontWeight: 700 }}>
              使用者管理
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                variant="outlined"
                placeholder="搜尋"
                size="small"
                sx={{ width: { xs: "100%", md: 400 } }}
                onChange={handleSearch}
              />
              <Divider orientation="vertical" flexItem />
              <CreateUserModal />
            </Stack>
          </Div>
          <CardContent>
            <UserTable dataSource={userList} />
          </CardContent>
        </Card>
      </Div>
    </Div>
  );
};

export default UserManagementPage;
