import Div from "@jumbo/shared/Div";
import { Divider, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { fchTaskList } from "app/redux/actions/search";
import { cngEndDate, cngStartDate } from "app/redux/actions/task";
import TaskList from "app/shared/TaskList";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const errorMsg = {
  0: "",
  1: "結束日期不可大於開始日期",
  2: "結束日期不可大於今天",
  3: "開始日期不可大於結束日期",
};

const NoteTaskPage = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const [error, setError] = useState(0);
  const [cTime, setCtime] = useState(10);
  const [page, setPage] = useState(1);

  const uId = useSelector(({ app }) => app.user.id);

  const task = useSelector(({ search }) => search.getIn(["results", "task"]));

  const config = useSelector(({ task }) => task.get("config"));

  const status_count = useSelector(({ search }) => search.get("status_count"));

  const handleStatusChange = e => {
    const task_status = e.target.value;
    setSearchParams({ status: task_status });
  };

  const handleCngStartDate = date => {
    if (moment(date).diff(moment(config.end_date)) > 0) return setError(1);
    dispatch(cngStartDate(moment(date).format("YYYY-MM-DD")));
    setError(0);
  };

  const handleCngEndDate = date => {
    if (moment(date).diff(moment()) > 0) return setError(2);
    if (moment(date).diff(moment(config.start_date)) < 0) return setError(3);
    dispatch(cngEndDate(moment(date).format("YYYY-MM-DD")));
    setError(0);
  };

  const fchMore = () => {
    setPage(page + 1);
    dispatch(
      fchTaskList(uId, config.start_date, config.end_date, 20, page + 1)
    );
  };

  const taskMemo = useMemo(() => task, [task]);

  useEffect(() => {
    dispatch(fchTaskList(uId, config.start_date, config.end_date));
  }, [uId, config.start_date, config.end_date]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCtime(cTime - 1);
    }, 1000);
    if (cTime < 0) {
      dispatch(
        fchTaskList(uId, config.start_date, config.end_date, 10, 1, true)
      );
      setCtime(10);
    }
    return () => clearInterval(interval);
  }, [cTime]);

  return (
    <Div sx={{ p: 2 }}>
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-start">
        <Stack
          gap={{ md: 4, xs: 2 }}
          flexDirection="row"
          alignItems="flex-start"
          mb={{ md: 0, xs: 1 }}>
          <Stack>
            <Stack flexDirection="row">
              <DatePicker
                disableFuture
                format="YYYY/MM/DD"
                value={config.start_date ? moment(config.start_date) : null}
                onChange={handleCngStartDate}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
              <Typography
                variant="h4"
                component="h2"
                sx={{ mx: 2, alignSelf: "center" }}>
                -
              </Typography>
              <DatePicker
                disableFuture
                format="YYYY/MM/DD"
                value={config.end_date ? moment(config.end_date) : null}
                onChange={handleCngEndDate}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
            </Stack>
            <Typography color="red" sx={{ my: 0, mt: 1 }}>
              {errorMsg[error]}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant="h4"
          component="h2"
          sx={{ alignSelf: { md: "center", xs: "left" } }}>
          {`${t("REFRESH")}：${cTime} 秒`}
        </Typography>
      </Stack>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <TaskList
        dataSource={taskMemo}
        status_count={status_count}
        fchMore={fchMore}
      />
    </Div>
  );
};

export default NoteTaskPage;
