import { Card, MenuItem, Select, Typography, Grid } from "@mui/material";
import { langDict } from "app/config/i18n/dict";
import { updContentLang, updLang } from "app/redux/actions/auth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const UserSettingCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(({ app }) => app.user);

  const typoStyle = {
    fontSize: 18,
    display: "flex",
  };
  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Typography variant="h2" sx={{ fontWeight: "bold", mb: 4 }}>
        {t("USER_SETTING")}
      </Typography>
      <Grid container gap={6}>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant="subtitle2" fontSize={18}>
              {t("INTERFACE_LANG")}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <Typography variant="subtitle2" color="#ccc" fontSize={18}>
              {langDict[user.config.lang]}
            </Typography>
          </Grid>
          <Grid item xs={3} md={9}>
            <Select
              size="small"
              value={user.config.lang}
              onChange={e => dispatch(updLang(e.target.value))}>
              <MenuItem value="zh">中文</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant="subtitle2" fontSize={18}>
              {t("CONTENT_LANG")}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <Typography variant="subtitle2" color="#ccc" fontSize={18}>
              {langDict[user.config.content_lang]}
            </Typography>
          </Grid>
          <Grid item xs={3} md={9}>
            <Select
              disabled
              size="small"
              value={user.config.content_lang}
              onChange={e => dispatch(updContentLang(e.target.value))}>
              <MenuItem value="zh">中文</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant="subtitle2" fontSize={18}>
              {t("MAGIC_POINT")}
            </Typography>
          </Grid>
          <Grid item xs={7} md={1} sx={typoStyle}>
            <Typography
              variant="subtitle2"
              color={
                user.point.temporary + user.point.permanent > 50
                  ? "green"
                  : "red"
              }
              fontSize={18}>
              {user.point.temporary + user.point.permanent}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant="subtitle2" fontSize={18}>
              {t("REF_CODE")}
            </Typography>
          </Grid>
          <Grid item xs={7} md={1} sx={typoStyle}>
            <Typography variant="subtitle2" color="green" fontSize={18}>
              {user.config.reference_code}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UserSettingCard;
