import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";
import ChipStack from "../ChipStack";
import { useJumboTheme } from "@jumbo/hooks";
import { noteTypeDict } from "app/utils/constants/noteType";
import { useTranslation } from "react-i18next";

// TODO: TAGS

const NoteListView = ({
  note,
  type,
  handleSelect,
  goEditPage,
  selectedNotes,
  handleDelModalOpen,
  labelList,
}) => {
  const NoteContent = () => {
    const { t } = useTranslation();

    const { theme } = useJumboTheme();
    const xsMatch = useMediaQuery(theme.breakpoints.down("sm"));
    const labels = labelList.filter(label => note.labels.includes(label.id));

    let title = note.title;

    if (!note.title) {
      const date = moment.unix(note?.created_at).format("YYYY-MM-DD");
      const titleText =
        note?.content.substring(0, 20).replace(/\n/g, "") + "...";
      const type = t(noteTypeDict[note?.source.type]);
      title = `${type} - ${date} - ${titleText}`;
    }

    return (
      <Box component="div" sx={{ flex: "initial", width: "90%" }}>
        <Typography
          variant="subtitle2"
          mb={0.5}
          component="div"
          sx={{ display: "flex", alignItems: "center" }}>
          {moment.unix(note.created_at).format("YYYY-MM-DD")}
          <Chip label={type} size="small" sx={{ ml: 1 }} />
        </Typography>
        <Typography variant="h4" mb={0.5} sx={{ fontWeight: 600, mb: 1 }}>
          {title}
        </Typography>
        <Typography
          variant="h4"
          mb={1}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {note.content}
        </Typography>

        <ChipStack dataSource={labels} size={xsMatch ? 3 : 5} />
      </Box>
    );
  };

  return (
    <ListItemButton
      alignItems={"flex-start"}
      sx={{
        px: 3,
        borderBottom: 1,
        borderBottomColor: "divider",

        "&:hover .update-task": {
          opacity: 1,
          backgroundColor: "common.white",
          transform: "translateY(-50%) scale(1)",
        },
      }}>
      <ListItemIcon sx={{ minWidth: 40, mt: 0 }}>
        <Checkbox
          edge="start"
          checked={selectedNotes.includes(note.id)}
          onChange={e => handleSelect(note.id, e)}
        />
      </ListItemIcon>
      <ListItemText
        onClick={() => goEditPage(note.id)}
        primary={<NoteContent />}
      />
      <Stack flexDirection="row" gap={1} alignSelf="center">
        <IconButton
          onClick={e => handleDelModalOpen(note.id, e)}
          sx={{
            opacity: 1,
            boxShadow: 1,
            color: "text.primary",
            backgroundColor: "common.white",
          }}>
          <DeleteIcon fontSize={"small"} />
        </IconButton>
      </Stack>
    </ListItemButton>
  );
};
/* Todo item prop define */
export default NoteListView;
