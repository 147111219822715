import React from "react";
import SettingPage from "app/pages/Setting";

const settingRoutes = [
  {
    path: "/settings",
    element: <SettingPage />,
  },
];

export default settingRoutes;
