export const SEARCH_NOTES = "SEARCH/SEARCH_NOTES";
export const SEARCH_NOTES_DONE = "SEARCH/SEARCH_NOTES_DONE";

export const FCH_NOTELIST = "SEARCH/FCH_NOTELIST";
export const FCH_NOTELIST_DONE = "SEARCH/FCH_NOTELIST_DONE";

export const FCH_TASKLIST = "SEARCH/FCH_TASKLIST";
export const FCH_TASKLIST_DONE = "SEARCH/FCH_TASKLIST_DONE";

export const FCH_NOTE = "SEARCH/FCH_NOTE";
export const FCH_NOTE_DONE = "SEARCH/FCH_NOTE_DONE";

export const FCH_VIEW_NOTE = "SEARCH/FCH_VIEW_NOTE";
export const FCH_VIEW_NOTE_DONE = "SEARCH/FCH_VIEW_NOTE_DONE";

export const FCH_NOTE_TASK = "SEARCH/FCH_NOTE_TASK";
export const FCH_NOTE_TASK_DONE = "SEARCH/FCH_NOTE_TASK_DONE";

export const SET_SEARCH_CONDITION = "SEARCH/SET_SEARCH_CONDITION";
export const DEL_SEARCH_CONDITION = "SEARCH/DEL_SEARCH_CONDITION";

export const REFRESH_NOTE_STATUS = "SEARCH/REFRESH_NOTE_STATUS";
export const REFRESH_NOTE_STATUS_DONE = "SEARCH/REFRESH_NOTE_STATUS_DONE";
