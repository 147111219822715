import {
  Box,
  Chip,
  Collapse,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { fchViewNote } from "app/redux/actions/search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom/dist";
import moment from "moment";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BadgeIcon from "@mui/icons-material/Badge";
import TagIcon from "@mui/icons-material/Tag";
import LabelIcon from "@mui/icons-material/Label";
import LinkIcon from "@mui/icons-material/Link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useTranslation } from "react-i18next";

import "../../shared/NoteEditor/Editor/styles.css";
import PasswordModal from "app/shared/PasswordModal";
import { cngPwdModalOpen } from "app/redux/actions/note";
import useAlert from "app/hooks/useAlert";
import { genHTML } from "app/utils/downloadDoc";
import MediaPlayer from "app/shared/MediaPlayer";
import MetaHelmet from "app/shared/Helmet";
import Div from "@jumbo/shared/Div";

import "../../themes/view.css";
import Loading from "app/shared/Loading";
import { aiGenTypes } from "app/utils/constants/aiGenTypes";

const NoteViewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { noteId } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isImmersive, setIsImmersive] = useState(false);
  const [note, setNote] = useState(null);

  const { showErrorAlert } = useAlert();

  const pwdModalOpen = useSelector(({ note }) =>
    note.getIn(["config", "pwdModalOpen"])
  );

  const labelList = useSelector(({ search }) =>
    search.getIn(["results", "labels"])
  );
  const selectedLabel = useSelector(({ label }) => label.get("list"));
  const user = useSelector(({ app }) => app.user);
  // const note = useSelector(({ search }) => search.getIn(['results', 'note']));

  const fchData = async (password = "") => {
    const res = await dispatch(fchViewNote(noteId, password));
    if (res && res === 401) {
      dispatch(cngPwdModalOpen(true));
      return false;
    }

    if (res && res === 404) {
      navigate("/403");
      return false;
    }

    setNote(res.data);
    return true;
  };

  const confirmPassword = async password => {
    const success = await fchData(password);
    if (!success) return showErrorAlert("密碼錯誤");
    dispatch(cngPwdModalOpen(false));
    return false;
  };

  const handleSwitchImmersive = e => {
    setIsImmersive(!isImmersive);
  };

  const handleChangeNoteView = value => {
    setSearchParams({ type: value });
  };

  useEffect(() => {
    if (noteId) {
      fchData();
    }
  }, [noteId]);

  useEffect(() => {
    if (note && note.permission === "private" && user.id !== note.user_id) {
      navigate("/404");
    }
  }, [user.id, note]);

  const IDArea = () => {
    return (
      <Grid
        component="div"
        id="section"
        item
        container
        md={12}
        xs={12}
        sx={{ py: 1 }}>
        <Grid
          item
          xs={2.6}
          md={1.2}
          lg={1.1}
          xl={0.76}
          sx={{ display: "flex", alignItems: "center", minWidth: 100 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mr: 0.5,
              minWidth: 50,
            }}>
            <BadgeIcon
              sx={{
                fontSize: 18,
                mr: 0.5,
              }}
            />
            <Typography variant="subtitle2" sx={{}}>
              ID
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} md={10}>
          <Typography variant="subtitle2">{note.id}</Typography>
        </Grid>
      </Grid>
    );
  };

  const TitleInput = () => {
    return (
      <Grid item md={12} xs={12}>
        <Typography variant="h1" sx={{ py: 1, pt: 3 }}>
          {note.title}
        </Typography>
      </Grid>
    );
  };

  const LabelSelector = () => {
    if (!labelList) return null;

    const value = labelList.filter(item => selectedLabel.includes(item.id));

    return (
      <Grid item container md={12} xs={12} sx={{ py: "2px" }}>
        <Grid
          item
          xs={2.6}
          md={1.2}
          lg={1.1}
          xl={0.73}
          sx={{ display: "flex", alignItems: "center", minWidth: 94 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}>
            <LabelIcon
              sx={{
                fontSize: 18,
                mr: 0.5,
              }}
            />
            <Typography variant="subtitle2">{t("NOTE_LABELS")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8.56} md={10.2} lg={10.9} xl={10.8}>
          <Stack
            flexDirection="row"
            gap={1}
            boxSizing="content-box"
            sx={{
              flexWrap: "wrap",
            }}>
            {value.map(val => (
              <Chip
                key={val.id}
                size="small"
                variant="outlined"
                label={val.name}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const TagArea = () => {
    return (
      <Grid
        item
        container
        component="div"
        md={12}
        xs={12}
        sx={{
          py: 1,
        }}>
        <Grid item xs={2.6} md={1.1} lg={1.15} xl={0.75} sx={{ minWidth: 94 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}>
              <TagIcon sx={{ mr: 0.5, fontSize: 18 }} />
              <Typography
                variant="subtitle2"
                mr={0.5}
                sx={{
                  fontSize: 14,
                }}>
                {t("NOTE_TAGS")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={10}>
          <Stack
            flexDirection="row"
            gap={1}
            boxSizing="content-box"
            sx={{
              flexWrap: "wrap",
            }}>
            {note.tags.length === 0 && (
              <Typography fontSize="14" color="#c5c9cb">
                {t("NOTE_TAGS_GENERATING")}
              </Typography>
            )}
            {note.tags.map(tag => (
              <Chip key={tag} size="small" variant="outlined" label={tag} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const DateArea = () => {
    const date = moment.unix(note.created_at).format("YYYY-MM-DD");
    return (
      <Grid
        item
        container
        md={12}
        xs={12}
        sx={{
          py: 1,
        }}>
        <Grid item xs={2.6} md={1.2} lg={1.2} xl={0.75} sx={{ minWidth: 100 }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
            }}>
            <AccessTimeIcon
              sx={{
                mr: 0.5,
                position: "relative",
                top: "-1px",
                fontSize: 18,
              }}
            />

            <Typography variant="subtitle2">{t("NOTE_CREATED_AT")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8} md={8}>
          <Typography variant="subtitle2">{date}</Typography>
        </Grid>
      </Grid>
    );
  };

  const UrlInput = () => {
    return (
      <Grid item container md={12} xs={12} sx={{ py: 1 }}>
        <Grid
          item
          xs={2.6}
          md={1.2}
          xl={0.74}
          sx={{ display: "flex", alignItems: "center", minWidth: 98 }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              mr: 0.5,
              position: "relative",
              top: 2,
            }}>
            <LinkIcon
              sx={{ mr: 0.5, position: "relative", top: 1, fontSize: 19 }}
            />
            <Typography variant="subtitle2">{t("NOTE_URL")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8.4} md={10} lg={10.4} xl={11} sx={{ width: "100%" }}>
          <TextField
            disabled
            variant="standard"
            defaultValue={note.url}
            sx={{ width: "100%", border: 0, borderRadius: 0, height: "100%" }}
            inputProps={{
              style: {
                padding: 0,
                height: "100%",
              },
            }}
            InputProps={{
              endAdornment: note.url && (
                <Link href={note.url} target="_blank">
                  <ArrowForwardIcon />
                </Link>
              ),
              style: {
                height: "100%",
              },
              disableUnderline: true,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const NoteViewSelect = ({ onChange, value, note }) => {
    const handleChange = e => {
      onChange(e.target.value);
    };

    return (
      <Select
        sx={{ maxWidth: 375, width: { md: 400, xs: "100%" } }}
        size="small"
        value={value}
        onChange={handleChange}>
        <MenuItem value="content">筆記</MenuItem>
        <MenuItem value="summary">摘要</MenuItem>
        {note?.translate && <MenuItem value="translate">翻譯</MenuItem>}
        {note.hasOwnProperty("generated") &&
          note.generated.map((item, index) => (
            <MenuItem key={index} value={`generated-${index}`}>
              智慧寫文
              {` - ${aiGenTypes.find(type => type.value === item.type)?.label}`}
            </MenuItem>
          ))}
      </Select>
    );
  };

  if (!note && pwdModalOpen) {
    return (
      <PasswordModal
        isOpen={pwdModalOpen}
        confirmPassword={confirmPassword}
        handleClose={() => dispatch(cngPwdModalOpen(false))}
      />
    );
  }

  if (!note) return <Loading />;

  const contentStr = genHTML(note, searchParams.get("type") || "content");

  return (
    <>
      <MetaHelmet
        description={note.content}
        title={note.title}
        keywords={note.tags}
      />
      <Box component="div">
        <Grid
          id="view-bar"
          container
          sx={{
            width: "100%",
            borderBottom: "1px solid #DEE2E6",
            pb: isImmersive ? 0 : 2,
            px: 2,
          }}>
          <TitleInput />
          <Collapse in={!isImmersive} sx={{ width: "100%" }}>
            <IDArea />
            <DateArea />
            {/* <TagArea /> */}
            <LabelSelector />
            <UrlInput />
            <NoteViewSelect
              onChange={handleChangeNoteView}
              value={searchParams.get("type") || "content"}
              note={note}
            />
          </Collapse>
        </Grid>

        {note.source.type === "audio" &&
          searchParams.get("type") !== "summary" && (
            <MediaPlayer
              content={note[searchParams.get("type") || "content"]}
              url={note.fileurl}
              isImmersive={isImmersive}
              handleSwitchImmersive={handleSwitchImmersive}
            />
          )}

        {(note.source.type !== "audio" ||
          searchParams.get("type") === "summary") && (
          <Div
            id="view-content"
            sx={{
              p: 2,
              px: 4,
              minHeight: {
                md: "calc(100dvh - 416px)",
                xs: "calc(100dvh - 380px)",
              },
              fontSize: 16,
            }}
            dangerouslySetInnerHTML={{ __html: contentStr }}
          />
        )}

        <Box
          id="view-footer"
          sx={{
            py: 2,
            borderTop: "1px solid #DEE2E6",
            height: 55,
          }}>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Copyright©{new Date().getFullYear()} AIMochi.ai All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default NoteViewPage;
