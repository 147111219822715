import Immutable from "immutable";

import * as __ from "../types/task";
import moment from "moment";

const INIT_STATE = Immutable.OrderedMap({
  config: {
    status: "undone",
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    page: 1,
    total_count: 0,
  },
});

const taskReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case __.CNG_STATUS:
      return _.setIn(["config", "status"], payload.status);
    case __.CNG_START_DATE:
      return _.setIn(["config", "start_date"], payload.start_date);
    case __.CNG_END_DATE:
      return _.setIn(["config", "end_date"], payload.end_date);
    default:
      return _;
  }
};

export default taskReducer;
